
import { jsPDF } from 'jspdf';

import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import WordDocModel from './WordDocModel';
import PDFDocModel from './pdfDocModel';
import { IS_TESTING_LOCALLY_WITH_NO_AI } from './constants';


export default class DataModel {


    static globalCallHttpFunction = async (functionName = '', body = {} ) => {
        var result = null;
      
          const url =  `https://us-central1-${global.serverName}.cloudfunctions.net/${functionName}`
          
          const response = await fetch(url,{method: 'POST', headers:{'Content-Type':'application/json'}, body: JSON.stringify(body)}).catch(e => {
            return null;
          })
          if(!response?.ok) {
            return null;
          }
          const data = await response.json();
          result = data
        
        return result?.reply;
  }
  // NOTE: if getAsJson is passed, result will be JSON for the word doc
  static createCurriculum = async (body = {}, isKit = false) => {
      if(IS_TESTING_LOCALLY_WITH_NO_AI) {
        const data1 = demoJSON();
        data1.email = body?.email;
        //PDFDocModel.handleConvertToPDF(data1, `${body.studentName} - ${body.subject}`, body?.studentName, body?.applicationNumber)
       WordDocModel.handleConvertToDocx(data1, body?.subject + '-ESA-Curriculum')
      return;
      }
    const data = await this.globalCallHttpFunction('createPdfPlanForCurriculumV5', {...body, getAsJson: true})
    if(data) {
    if(body?.getAsJson) {
      // create word
      WordDocModel.handleConvertToDocx(data, body?.subject + '-ESA-Curriculum', body?.email)
      return;
    } else {
      // convert to PDF.
      PDFDocModel.handleConvertToPDF(data, `${body.studentName} - ${body.subject}`, body?.studentName, body?.applicationNumber, body?.email, isKit, body?.materials )
    return;
    }
    }
   
  }


    static isNewOrSuccessPage = () => {
      const href = window.location.href
      const a = href.includes('newlessonplan.com/new') 
      || href.includes('localhost:3000/new')
      return a;
    }
}


export function getDemoTable () {

    return `<table style="width:100%; border-collapse: collapse;">
  <tr style="min-height:100px">
    <th style="background-color: #f2f2f2; text-align: left;" colspan="2">Student Name: Bishop</th>
    <th style="background-color: #f2f2f2; text-align: left;" colspan="2">Grade: Third</th>
  </tr>
  <tr>
    <th style="background-color: #d9d9d9;">Application Number</th>
    <th style="background-color: #d9d9d9;">890808</th>
    <th style="background-color: #d9d9d9;">Subject</th>
    <th style="background-color: #d9d9d9;">Science</th>
  </tr>
  <tr>
    <th style="background-color: #f2f2f2; text-align: left;" colspan="4">Scope and Overview</th>
  </tr>
  <tr>
    <td colspan="4">Introduction to basic scientific concepts and principles through hands-on experiments and activities.</td>
  </tr>
  <tr>
    <th style="background-color: #f2f2f2; text-align: left;" colspan="4">Sequence/Methods of Teaching/Lessons</th>
  </tr>
  <tr>
    <th style="background-color: #d9d9d9;">Week</th>
    <th style="background-color: #d9d9d9;">Topic</th>
    <th style="background-color: #d9d9d9;">Activities</th>
    <th style="background-color: #d9d9d9;">Materials</th>
  </tr>
  <tr>
    <td>1</td>
    <td>Introduction to Matter</td>
    <td>Experiment: Sink or Float</td>
    <td>Digital books on states of matter</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Plant Life Cycle</td>
    <td>Observing plant growth</td>
    <td>Digital books on plant life cycles</td>
  </tr>
  <tr>
    <td>3</td>
    <td>Weather Patterns</td>
    <td>Creating a weather chart</td>
    <td>Digital books on weather phenomena</td>
  </tr>
  <tr>
    <th style="background-color: #f2f2f2; text-align: left;" colspan="4">Supplied Materials and Educational Use</th>
  </tr>
  <tr>
    <td colspan="4">Digital books will be used to supplement classroom learning, providing interactive and engaging content for students to explore scientific concepts in depth.</td>
  </tr>
</table>`
}


export const demoJSON = () => {
  return {
    "studentName": "Bishop",
    "grade": "Third",
    "applicationNumber": "890808",
    "subject": "Science",
    "scopeAndOverView": "Introduction to basic scientific concepts and principles through hands-on experiments and activities.",
    "lessonPlan": [
      {
        "week": 1,
        "topic": "Introduction to Matter",
        "activities": "Experiment: Sink or Float",
        "materials": "Books on states of matter"
      },
      {
        "week": 2,
        "topic": "Plant Life Cycle",
        "activities": "Observing plant growth",
        "materials": "Books on plant life cycles"
      },
      {
        "week": 3,
        "topic": "Weather Patterns",
        "activities": "Creating a weather chart",
        "materials": "Books on weather phenomena"
      }
    ],
    "suppliedMaterialsAndEducationalUse": "Books will be used to supplement classroom learning, providing detailed information and illustrations on scientific topics. Pens will be used for note-taking and writing observations during experiments. Stickers will be used as rewards for participation and achievement, encouraging engagement and motivation in learning activities."
  }
  
  
  
}