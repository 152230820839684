import React from 'react'

import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import './alertify-custom.css'



export const FormButton = ({onClick = () => {},footNote = '', style ={}, loading = false, label = '', textColor='white', disabled = false, isPrimary = false, isDanger = false, isFullWidth = false, superExtraClass = '', ...props }) => 
{

    let extraClass = isPrimary == true ? '' : ''
    if(isFullWidth) {
      extraClass = extraClass + ' w-100 '
    }
    if(isDanger) {
      extraClass = extraClass + ' btn-light-danger ' 
    }
    extraClass = extraClass + ' ' + superExtraClass
    
    return(  <div className="w-full flex"><a
        className={`cursor-pointer transition-all transform hover:scale-105 ${extraClass}  bg-indigo-600 text-${textColor} font-bold rounded hover:bg-indigo-500 text-center p-2 "` }
        onClick={loading || disabled ? null :
          isDanger ? 
          () => {
            // show alert
            showMagniAlert(onClick)
            
          }
          :
          onClick}
        type="button"
        data-bs-dismiss="modal"
        style={style}
      >
        {loading ? "Loading..." : label}
        {loading && (
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        )}
        {
            props.children
        }
      </a>
      {
        footNote?.length ? <small className="form-text text-muted px-2">{footNote}</small> : null 
      }
      </div> 
      )
}


export const showMagniAlert = (onClick = () => {}, title = 'Confirm', message = 'Are you sure? ', ) => {
  alertify.confirm(title, message,  () => onClick(), () => {})
}