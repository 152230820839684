import React, { useState } from 'react';

import {FormInput} from './components/formInput'
import { ScreenContainer } from './App';

export const  Help = () =>  {
    const [search, setSearch] = useState('');

    const curriculumTopics = [
        {
            title: 'How to submit reimbursement?',
            content: (
                <>
                    <a>
                        <p
                            className="p-10 fs-5 text-indigo-500 underline cursor-pointer"
                            onClick={() => (window.location.href = '/how-reimbursement-works')}
                        >
                            Click here to see a step-by-step guide to get reimbursement on your purchases here.
                        </p>
                    </a>
                </>
            ),
        },
        {
            title: 'Important for Approval',
            content: (
                <p className="p-10 fs-5">
                    When entering the materials on the "New Curriculum" page, try to be as detailed as possible especially
                    if you purchase items that are not on the list. For example, instead of just entering "Books," enter the
                    book title as well: "Books - The Tale of Two Cities."
                </p>
            ),
        },
        {
            title: 'Why do I need a curriculum?',
            content: (
                <p className="p-10 fs-5">
                    ESA guidelines change often, presenting a risk of rejecting your ESA-based purchases. To avoid that
                    risk, you need to provide a curriculum accompanying each purchase, explaining the intended usages of
                    each item on your purchase list...
                </p>
            ),
        },
        {
            title: 'Why are you charging $0.99?',
            content: (
                <p className="p-10 fs-5">
                    As parents, we're truly doing this to help ourselves and other parents with ESA approvals...
                </p>
            ),
        },
        {
            title: 'What does this website do?',
            content: (
                <p className="p-10 fs-5">
                    We generate an approvable PDF file and a curriculum for each item you're intending to purchase with
                    your ESA funds. Or you may purchase a learning kit for your student that's backed by ESA-compliant tutors.
                </p>
            ),
        },
        {
            title: 'What do I get after I pay $0.99?',
            content: (
                <>
                    <p className="p-10 fs-5">
                        Will give you a well-crafted and formatted PDF (or a word doc if you chose not to enter a student
                        name) according to ESA guidelines, similar to this sample:
                    </p>
                    <img
                        src={require('./assets/images/Sample Report.png')}
                        style={{
                            width: '80%',
                            margin: '0 auto',
                            borderColor: 'black',
                            borderWidth: 1,
                            borderRadius: 12,
                        }}
                        alt="Sample Report"
                    />
                </>
            ),
        },
        {
            title: 'How do I upload the curriculum PDF to ESA?',
            content: (
                <>
                    <p className="p-10 fs-5">
                        Once we give you the curriculum PDF, simply drag and drop it onto your ESA curriculum information
                        page as shown in the image below:
                    </p>
                    <img
                        src={require('./assets/images/how_to_upload_curriculum.png')}
                        style={{
                            width: '80%',
                            margin: '0 auto',
                            borderColor: 'black',
                            borderWidth: 1,
                            borderRadius: 12,
                        }}
                        alt="How to Upload Curriculum"
                    />
                </>
            ),
        },
        {
            title: 'Can I do this at home?',
            content: (
                <p className="p-10 fs-5">
                    You can generate a curriculum with AI products, but it requires a lot of time and effort. For only
                    $0.99, we do all the work for you...
                </p>
            ),
        },
    ];



    const [kitsTopics, setKitTopics] =React.useState( [
        {
            title: 'What do I do with the generated Invoice?',
            content: (
                <>
                   <a>
                        <p
                            className="p-10 fs-5 text-indigo-500 underline cursor-pointer"
                            onClick={() => (window.location.href = '/how-direct-pay-works')}
                        >
                            Click here to see a step-by-step guide to submit the generated invoice and curriculum to ClassWallet.
                        </p>
                    </a>
                </>
            ),
        },
        
        {
            title: 'Why not buy from Amazon?',
            content: (
                <p className="p-10 fs-5">
                   The biggest issue when buying from Amazon is the constant price changes and shipping delays which result in <strong className='text-danger'>rejection of your order</strong>. 
                   When you get your Amazon order through us, you don't have to worry about price or shipping changes. We honor the price we give you at checkout and that will not change.
                   Hence your approval chances are 100%.
                </p>
            ),
        },
        {
            title: 'What do I do with the generated invoice and curriculum?',
            content: (
                <p className="p-10 fs-5">
                   When you place an order, we generate an invoice and curriculum for you. Upload those to your ClassWallet and submit for approval.
                </p>
            ),
        },
        {
            title: 'What happens after I upload my invoice and curriculum to ClassWallet?',
            content: (
                <p className="p-10 fs-5">
                   ClassWallet will review and approve your order. Once approved, we'll get notified and ship your Amazon order directly to your shipping address.
                </p>
            ),
        },
        {
            title: 'When do I get my Amazon order after I submit for approval?',
            content: (
                <p className="p-10 fs-5">
                   As soon as ClassWallet approves your invoice, we'll get notified and ship your Amazon order directly to your shipping address.
                </p>
            ),
        },
        
    ])


    const [topics, setTopics] = React.useState(kitsTopics)

    const filteredTopics = topics.filter(
        (topic) =>
            topic.title.toLowerCase().includes(search.toLowerCase()) ||
            topic.content.props.children.toLowerCase?.()?.includes(search.toLowerCase())
    );

    const [active, setActive] = useState("learningKits");


    const renderToggler = () => {
        return(    <div className="flex items-center justify-center space-x-2 p-4">
            <button
              onClick={() => {
                setActive("learningKits")
                setTopics(kitsTopics)
            }}
              className={`px-4 py-2 rounded-md text-sm font-medium fs-6 ${
                active === "learningKits"
                  ? "bg-indigo-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              Help with Amazon Orders
            </button>
            
            <button
              onClick={() => {
                setActive("curriculums")
                setTopics(curriculumTopics)
            }}
              className={`px-4 py-2 rounded-md text-sm font-medium fs-6 ${
                active === "curriculums"
                  ? "bg-indigo-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
             Help with Curriculums
            </button>
            
          </div>
      )
    }

    return (
        <>
            <ScreenContainer title="Help & Tips">
            {renderToggler()}

                <div className="grid p-10">
                    <FormInput
                        placeholder="Search topics..."
                        text={search}
                        onTextChange={(e) => setSearch(e)}
                        className="mb-5"
                    />

                   
                    {filteredTopics.map((topic, index) => (
                        <div key={index} className="mb-10">
                            <p className="h1 fs-bold text-left px-10">{topic.title}</p>
                            {topic.content}
                        </div>
                    ))}
                </div>
            </ScreenContainer>
        </>
    );
}

