import React, { useState } from 'react';
import './SwitchControl.css'; // Import the CSS file

const SwitchControl = ({label = 'Add disability?', checked = false, onToggle = () => {}}) => {

  return (
    <div className="switch-container">
          <p className='fw-bold'>{label}</p>
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={onToggle}
        />
        <span className="slider"></span>
      </label>
      {/* <div ><div className="bi bi-info-circle text-[gray] pl-5 mt-1 "></div></div> */}
    </div>
  );
};

export default SwitchControl;
