import React, { useState } from "react";
import { saveAs } from "file-saver";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  HeadingLevel,
  Border,
  Spacing,
  convertInchesToTwip,
  ShadingType,
} from "docx";

import {
  TextRun,
  AlignmentType,
  WidthType,
  VerticalAlign,
  BorderStyle,
} from "docx";
import { demoJSON } from "./DataModel";
import EmailModel from "./emailModel";

export default class WordDocModel {


  static handleConvertToDocx = (data = demoJSON(), fileName = "", email = '') => {
   // data = demoJSON()
  try{
    data = JSON.parse(data)
  }
  catch {
    return;
  }
    const doc = new Document({
      title: "ESA Curriculum",
      sections: [
        {
          children: [
            new Paragraph({
              text: "",
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              text: "",
              heading: HeadingLevel.HEADING_1,
              border: { bottom: { size: 1, color: "#ffffff" } },
            }),

            this.createRow("Student Name: ", ' ', true),
            this.createRow("Application Number: ", ' ', false),
            this.createRow("Grade: ", data.grade, true),
            this.createRow("Subject: ", data.subject, false),
            this.createRow("Scope and Overview: ", data.scopeAndOverView, true),
            this.createRow("Lesson Plan: ", " ", false),
            ...data.lessonPlan.map((f) =>
                this.createRow(
                "",
                `Week: ${f.week} - ${f.topic}: ${f.activities}`,
                false
              )
            ),

            this.createRow(
              "Supplied Materials and Education Use: ",
              data.suppliedMaterialsAndEducationalUse,
              false,
              true
            ),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, fileName);
      // send email
      const fullFileName = `${fileName}.docx`
      EmailModel.sendCurriculumToEmail(blob, fullFileName, email, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    });
  };

  static createRow = (
    label = "",
    text = "",
    isOdd = true,
    isHighlight = false
  ) => {
    const color = isHighlight ? "ebd534" : isOdd ? "d9d9d9" : "ffffff";
    // Main paragraph with text
    const p = new Paragraph({
      heading: HeadingLevel.HEADING_1,

      children: [
        new TextRun({
          text: label,
        }),
        new TextRun({
          text: text,
          heading: HeadingLevel.HEADING_1,
          color: "000000",
        }),
      ],
      alignment: AlignmentType.LEFT,
      borders: {
        top: { style: BorderStyle.SINGLE, size: 1, color: color },
        bottom: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
        left: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
        right: { style: BorderStyle.SINGLE, size: 1, color: "000000" },
      },
      shading: {
        type: ShadingType.CLEAR,
        fill: color, // Background color in hex, without the '#'
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      verticalAlign: VerticalAlign.CENTER,
      spacing: {
        before: 400,
        after: 400, // Adjust the spacing after the paragraph to create visual separation
      },
    });
    return p;
  };
}
