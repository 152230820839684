import React, { useState, useEffect } from "react";
import "./components/KitGrid.css";
import { FormButton, showMagniAlert } from "./components/formButton";
import { useParams } from "react-router-dom";
import LearningKitsModel from "./utils/LearningKitsModel";
import { ALLOWED_REFERRAL_CODES, getSetKitsGlobal, GRADE, SAVE_ITEM_KEY, SUBJECT } from "./constants";
import _ from 'lodash'
import { FormInput } from "./components/formInput";
import { getKitInvoiceHtmlTemplate } from "./emailTemplates/kitInvoiceTemplate";
import PDFDocModel from "./pdfDocModel";
import alertify from "alertifyjs";
import { ScreenContainer } from "./App";
import { FeaturesList } from "./Home";
import { validateEmail } from "./NewCurriculum";
import { FormSelect } from "./components/formSelect";
import { LoadingSkeleton } from "./components/LoadingSkeleton";
import SwitchControl from "./components/SwitchControl";


export const KitCheckoutForm = ({materials, _subject = '', checkoutEnabled = true,  total, kit = [{description: '', details: {shipping: 0, tax: 0, total: 0}}], onBack = () => {}, noHeader = false}) => {


const [subject, setSubject] = React.useState(_subject)
const [loading, setLoading] = React.useState('')
  const [studentName, setStudentName] = React.useState('')
  const [applicationNumber, setApplicationNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [streetAddress, setStreetAddress] = React.useState('')
  const [apt, setApt] = React.useState('')
  const [city, setCity] = React.useState('')
  const [state, setState] = React.useState('AZ')
  const [zip, setZip] = React.useState('')
  const [grade, setGrade] = React.useState('')
const [isSpecialNeeds, setIsSpecialNeeds] = React.useState(false)
  
  const [referralCode, setReferralCode] = React.useState('')

  const [showShippingInfo, setShowShippingInfo] = React.useState(false);


  const onCheckout = () => {
    if(!showShippingInfo) {
      if(!validateEmail(email)) {
        alertify.alert("Invalid Email", "ESA requires your email to review/approve purchases. Please provide a valid email to continue.")
        return;
      }
      if(!grade?.length) {
        alertify.alert("Grade Required", "Grade level is required to generate the supplemental curriculum.")
        return;
      }

      if(!subject?.length) {
        alertify.alert("Subject Required", "Subject is required to generate the supplemental curriculum.")
        return;
      }
      setShowShippingInfo(true);
      return;
    }
  
    if(!city?.length || !state?.length || !zip?.length || !streetAddress?.length || !studentName?.length || !applicationNumber?.length || !email?.length || !grade?.length || !subject?.length) {
      alertify.alert("Missing Info", "Please fill out all fields as they're required by ESA for approval.")
      return;
    }
    
    if(!checkoutEnabled) {
      setTimeout(() => {
        onCheckout()
      }, 500);
      return;
    }
    performCheckout();
  }


  const performCheckout = async () => {
    
    setLoading(true)
    if(!kit?.title) {
      kit.title = `${subject}`
    }
    const enteredCode = referralCode || ''
    const isValidCode = await LearningKitsModel.checkRCode(enteredCode);
    const code = isValidCode ? enteredCode : 'NLP' 
    const invoiceNumber = LearningKitsModel.generateUniqueInvoiceNumber(code)
    const html = getKitInvoiceHtmlTemplate({invoiceNumber: invoiceNumber, issueDate: formatDate(), billToEmail: email, billToName: studentName,
      kit: kit, billToAddress: `${streetAddress} ${apt?.length ? '#'+apt : ''}, ${city} ${state} ${zip}`,

    })

    // admin html
    const adminHtml = getKitInvoiceHtmlTemplate({invoiceNumber: invoiceNumber, issueDate: formatDate(), billToEmail: email, billToName: studentName, isAdmin: true,
      kit: kit, billToAddress: `${streetAddress} ${apt?.length ? '#'+apt : ''}, ${city} ${state} ${zip}`,

    })

    // generate curriculum and invoice
    const data = {studentName: studentName, applicationNumber: applicationNumber,
      subject: subject,
      grade: grade, materials:materials, 
      adminHtml: adminHtml, 
      html: html, isKit: true,invoiceNumber: invoiceNumber,
       getAsJson: false, email: email};
       if(isSpecialNeeds) {
        data.disability = 'Special Needs'
       }
       setLoading(false)
    //save data 
    sessionStorage.setItem(SAVE_ITEM_KEY, JSON.stringify(data))
     window.location.href = '/success'
  }

  const renderForm = () => {
    return( <div className="p-6">
      <h3 className="text-md  text-gray-500 mb-6">
      Please provide these <span className="underline text-orange-500">ESA-required details</span> to generate your invoice and curriculum for easy drag-and-drop on ClassWallet.
      </h3>

      <div className="mt-5">
        {/* Student and ESA Information */}
        <div className="row">
        
        <div className="col">
        <FormInput label="Student Name" text={studentName} onTextChange={e => setStudentName(e)} autoFocus
        />
        </div>
        <div className="col">
        <FormInput label="ESA Application Number"  text={applicationNumber} onTextChange={e => setApplicationNumber(e)} />
        </div>
        </div>
    
        <div className="row">
        <div className="col">
        <FormInput label="ESA Email"  text={email} onTextChange={e => setEmail(e)} />
        </div>
       
        <div className="col">
        <FormSelect className=" mt-3" _options={GRADE.map(f => ({label: f, value:f}))} label="Grade" onSelect={e => setGrade(e)}  placeholder="Select grade..."  />
        </div>
     
        </div>
        <div>
        <SwitchControl checked={isSpecialNeeds} onToggle={() => setIsSpecialNeeds(!isSpecialNeeds)} label="Special needs student?"/>
        </div>

        <hr className="my-6 border-gray-300" />

      {showShippingInfo? <div>

        <div className="row">
        
        <div className="col">
        <FormInput label="Street Address"  text={streetAddress} onTextChange={e => setStreetAddress(e)} />
        </div>
        <div className="col">
        <FormInput label="Apartment/Suite (Optional)"  text={apt} onTextChange={e => setApt(e)} />
        </div>
        </div>


        <div className="row">
        
        <div className="col">
        <FormInput label="City"  text={city} onTextChange={e => setCity(e)} />
        </div>
        <div className="col">
        <FormInput label="State"  text={state} onTextChange={e => setState(e)} />
        </div>
        <div className="col">
        <FormInput label="Zip Code" maxLength={5}  text={zip} onTextChange={e => setZip(e)} />
        </div>
        </div>
      <hr/>

       {1===1 ? null :  <div className="col mt-4">
        <FormInput label="Do you have a referral code?" maxLength={5}   text={referralCode} onTextChange={e => setReferralCode(e)} />
        </div>}
      </div> : null}

  <hr/>
{showShippingInfo ?  <h4 className="text-left text-lg   mt-4 px-4">
           <span className="font-bold text-purple-600">
  Total: ${total}
</span>
          </h4> : null}

  <div className="bg-gray-50 p-4 rounded-lg d-flex gap-2">
         
       
           {/* <FormButton label="Pay With Card & Submit For Reimbursement" isPrimary isFullWidth superExtraClass=" bg-black" /> */}
           <FormButton label={loading ? 'Loading...' :  !showShippingInfo ? 'Next: Shipping Info' :  "Generate Invoice for ClassWallet"} isPrimary isFullWidth
            loading={loading}
            onClick={onCheckout}
           />
         </div>
      </div>
    </div>)
  }


  const renderCheckoutForm = () => {

    if(noHeader) {
      return renderForm()
    }

    return( 
      
      <ScreenContainer title="Checkout" onBack={onBack}>
        
        {renderForm()}
       
     </ScreenContainer>
          )
  }



  
    return renderCheckoutForm()
  
};

const formatDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();

  return `${month}-${day}-${year}`;
};

