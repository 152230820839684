export const getReferralCodeEmailTemplate = (code) => {

    return `<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f4f4f9;
        }
        .email-container {
            max-width: 600px;
            margin: 20px auto;
            background-color: #ffffff;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            overflow: hidden;
        }
        .header {
            background-color: #6A1B9A;
            color: #ffffff;
            text-align: center;
            padding: 20px 10px;
        }
        .header h1 {
            margin: 0;
            font-size: 24px;
        }
        .content {
            padding: 20px 30px;
            text-align: center;
        }
        .content h2 {
            color: #6A1B9A;
            font-size: 20px;
        }
        .content p {
            font-size: 16px;
            color: #555555;
            margin: 10px 0;
        }
        .content .referral-code {
            font-size: 28px;
            font-weight: bold;
            color: #6A1B9A;
            margin: 20px 0;
        }
        .content .cta {
            margin: 20px 0;
        }
        .content .cta a {
            text-decoration: none;
            color: #ffffff;
            background-color: #6A1B9A;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
        }
        .footer {
            background-color: #6A1B9A;
            color: #ffffff;
            text-align: center;
            padding: 15px 10px;
            font-size: 14px;
        }
        .footer a {
            color: #ffd700;
            text-decoration: none;
        }
    </style>
</head>
<body>
    <div class="email-container">
        <!-- Header Section -->
        <div class="header">
            <h1>NewLessonPlan.com</h1>
        </div>

        <!-- Content Section -->
        <div class="content">
            <h2>Your Unique Referral Code</h2>
            <p>Earn money every time your referral makes an approved purchase of a learning kit from us!</p>
            <p class="referral-code">${code}</p>
            <p>Share this code with your friends and family to start earning today.</p>
            <div class="cta">
                <a href="https://newlessonplan.com" target="_blank">Visit NewLessonPlan.com</a>
            </div>
        </div>

        <!-- Footer Section -->
        <div class="footer">
            <p>Thank you for sharing and supporting us!</p>
            <p>For more information, visit our <a href="https://newlessonplan.com" target="_blank">website</a>.</p>
        </div>
    </div>
</body>
</html>
`
}