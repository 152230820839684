import toast, { Toaster } from 'react-hot-toast';

export const IS_TESTING_LOCALLY_WITH_NO_AI = false;

export const SUBJECT = [
  "English Language Arts",
  "Mathematics",
  "Science",
  "Social Studies",
  "Art",
  "Physical Education",
  "Fine Arts",
  "Foreign Languages",
  "Computer Science and Technology",
  "History",
  "Health",
  "Music Education",
  "Bible Studies",
  "Home Economics",
  "Economics"
];

export const GRADE = [
  "Preschool",
  "Kindergarten",
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "Twelfth",
  "College"
];

export const DISABILITY = [
  "Autism",
  "ADHD",
  "Down Syndrome",
  "Cerebral Palsy"
]

export const MATERIALS = [
  "Books",
  "Audio books",
  "Digital books",
  "Coloring books",
  "Magazines",
  "Educational workbooks",
  "Planners",
  "Calendars",
  "Dictionaries",
  "Thesaurus",
  "Educational flash cards",
  "Prompt cue cards",
  "Periodic tables",
  "Writing utensils",
  "Pens",
  "Markers",
  "Pencils",
  "Highlighters",
  "Crayons",
  "Chalk",
  "Art supplies",
  "Art kits",
  "Education based art supplies",
  "School supplies",
  "Index cards",
  "Poster boards",
  "Paper",
  "Folders",
  "Binders",
  "Notebooks",
  "Staplers",
  "Scissors",
  "Tape",
  "Glue",
  "Eraser",
  "White out",
  "Sharpener",
  "Educational posters",
  "Small desk lamps",
  "Reading lamps",
  "Pencil grips",
  "Bookmarks",
  "Envelopes",
  "Rubber bands",
  "Dividers",
  "Hole punch",
  "Backpack",
  "Lunch bags",
  "Atlases",
  "Maps",
  "Globes",
  "STEM Kits",
  "STEM Items",
  "STEAM Kits",
  "STEAM Items",
  "Educational kits",
  "Manipulatives",
  "Math cubes",
  "Legos",
  "Dominoes",
  "Blocks",
  "Shapes",
  "Calculators",
  "Standard calculators",
  "Graphing calculators",
  "Scientific calculators",
  "Compass",
  "Navigation tools",
  "Protractors",
  "Rulers",
  "Educational DVDs",
  "Educational CDs",
  "Audio players",
  "Visual players",
  "Physical education items",
  "Sporting items",
  "Gym memberships",
  "Microscopes",
  "Telescopes",
  "Board games",
  "Strategy games",
  "Puzzles",
  "Timers",
  "Clocks",
  "Watches",
  "Dry erase boards",
  "Easels",
  "Bulletin board",
  "Bulletin board accessories",
  "Notebooks",
  "Pencils",
  "Pens",
  "Erasers",
  "Highlighters",
  "Markers",
  "Crayons",
  "Colored pencils",
  "Rulers",
  "Scissors",
  "Glue sticks",
  "Liquid glue",
  "Tape",
  "Index cards",
  "Binders",
  "Folders",
  "Notebook paper",
  "Construction paper",
  "Graph paper",
  "Poster boards",
  "Backpacks",
  "Lunch boxes",
  "Water bottles",
  "Pencil cases",
  "Calculator",
  "Scientific calculator",
  "Graphing calculator",
  "Stapler",
  "Staples",
  "Hole puncher",
  "Protractors",
  "Compass",
  "Whiteboard",
  "Whiteboard markers",
  "Chalk",
  "Chalkboard",
  "Hand sanitizer",
  "Disinfecting wipes",
  "Tissues",
  "Paper towels",
  "Art supplies",
  "Paints",
  "Paint brushes",
  "Sketchbooks",
  "Clay",
  "Craft sticks",
  "Glue guns",
  "Glue gun sticks",
  "Math manipulatives",
  "Educational games",
  "Flashcards",
  "Workbooks",
  "Textbooks",
  "Reading books",
  "E-books",
  "Educational software",
  "Laptop",
  "Tablet",
  "Headphones",
  "Printer",
  "Printer paper",
  "Printer ink",
  "Desk",
  "Desk chair",
  "Bookcase",
  "Lamps",
  "Desk organizers",
  "Bulletin boards",
  "Cork boards",
  "Sticky notes",
  "Desk calendar",
  "Planner",
  "Timer",
  "Math sets",
  "Microscope",
  "Science kits",
  "Globe",
  "Maps",
  "Language learning tools",
  "Musical instruments",
  "Sheet music",
  "PE equipment",
  "Sports equipment",
  "Uniforms",
  "Lab coats",
  "Safety goggles",
  "Backpack hooks",
  "Storage bins",
  "Flash drives",
  "External hard drives",
  "Drawing tablets",
  "Online course subscriptions",
  "Educational magazine subscriptions",
  "Virtual reality headsets",
  "3D printers",
  "Coding kits",
  "Robotics kits",
  "Weather stations",
  "Telescopes",
  "Magnifying glasses",
  "Binoculars",
  "Nature journals",
  "Gardening tools",
  "Seeds",
  "Soil",
  "Planters",
  "Pet supplies for class pets",
  "Aquariums",
  "Terrariums",
  "Art smocks",
  "Lab equipment",
  "Science fair display boards",
  "Book lights",
  "Standing desks",
  "Ergonomic chairs",
  "White noise machines",
  "Educational posters",
  "Wall maps",
  "Curriculum subscriptions",
  "Learning apps",
  "Interactive notebooks",
  "Language translation devices",
  "Microphones",
  "Webcams",
  "Document cameras",
  "Standing desk converters",
  "Ergonomic keyboards",
  "Ergonomic mouse",
  "Reading stands",
  "Reading pillows",
  "Math flashcards",
  "Science models",
  "Anatomy models",
  "Physics kits",
  "Chemistry sets",
  "Biology kits",
  "Digital subscriptions",
  "Online tutoring services",
  "Learning management systems",
  "Virtual labs",
  "Online textbooks",
  "Smart pens",
  "Digital notebooks",
  "Online learning platforms",
  "Subscription boxes for kids",
  "Learning games",
  "Interactive whiteboards",
  "Classroom subscriptions",
  "Language immersion programs",
  //"Travel expenses for educational trips",
  "Museum memberships",
  "Zoo memberships",
  "Aquarium memberships",
  "Historical site memberships",
  "Theatre tickets for educational shows",
  "Concert tickets for educational performances"
];

export const SPECIAL_TAGS = [
  "Book",
  "books",
  "Audio book",
  "Digital book",
  "Magazine",
  "Educational workbook",
  "Art supplies",
  "Art kit",
  "Stem kit",
  "Stem items",
  "Steam kit",
  "Steam items",
  "Educational kit",
  "Board game",
  "Strategy game",
  "Science kit",
  "Zoo",
  "Museum",
  "Musical",
  "Play",
  "Ballet",
  "Orchestra",
  "Educational subscription",
  "Educational software",
  "Educational app",
  "Educational poster",
  "Educational DVDs",
  "Educational CD",
  "Gym membership",
  "Language learning tool",
  "Musical instrument",
  "PE equipment",
  "Sporting items",
  "Online course subscription",
  "Educational magazine subscription",
  "Lab equipment",
  "Curriculum subscription",
  "Camera",
  "Science model",
  "Online tutoring service",
  "Learning management system",
  "Online learning platform",
  "Subscription boxes for kids",
  "Aquarium membership",
  "Learning games",
  "Historical site membership",
  "Theatre tickets for educational show",
  "Concert tickets for educational performances",
  "workbook",
  "Sports equipment",
  "membership",
  "subscription",
  "e-book",
  "ebook",
  "app",
  "Learning apps",
  "digital subscription",
  "online textbook",
  "virtual lab",
  "classroom subscription",
  "School supplies",
  "Biology kits",
  "Chemistry Set",
  "Physics kits",
  "Language immersion program",
  "Museum memberships",
  "Zoo memberships",
  "Education based art supplies",
  "educational games",
  "audio players"
];




export const SAVE_ITEM_KEY = 'curr_d'
export const COUPON_ITEM_KEY = 'COUPON'
export const COUPON_USAGE_DETAILS = 'COUPON_USAGE_DETAILS'




export const showToast = (message = '', isError = false, isWarning = false) => {
  const className =
  isWarning ? 'bg-light-warning text-black' :
  isError ? 'bg-light-danger text-black' : 'bg-light-success text-success'
  toast(message, {className})
}


export const ALLOWED_REFERRAL_CODES = ['PAGE']

export const blackListWords = ["Adult", "Professional", "Industrial", "Pro",      "Commercial", "Toy",    "Game",    "Sensory", "Motor",   "Sensory",  "Premium", "Designer", "Gift",       "Birthday", "Decor", "Décor", "Adults",     "Gifts", "Kids",      "Baby",   "Antique", "Executive", "Toys",  "Kit", "Kits", "Set"];
export const whiteListWords = ["Student", "Practical", "Practical",   "Beginner", "Portable",   "Gadget", "Activity", "Skill",  "Activity", "Practical", "Durable", "Item",   "Educational", "Office", "Office", "Office", "Students", "Gadgets", "Students", "Student", " ",       "Student",  "Gadgets", "Module", "Modules", 'Tool'];

export const cleanedStringFromBlackListWords = (inputString = '') => {
  if(!inputString?.length) {
    return inputString;
  }
  const blackListRegex = new RegExp(
    `\\b(${blackListWords.map(word => word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')).join('|')})\\b`, // Escape special characters
    'gi'
  );

  return inputString.replace(blackListRegex, (matchedWord) => {
    // Find the index of the matched word in the blacklist
    const index = blackListWords.findIndex(
      word => word.toLowerCase() === matchedWord.toLowerCase()
    );
    // Replace it with the corresponding word in the whitelist
    return index !== -1 ? whiteListWords[index] : matchedWord;
  });
};



export const getSetKitsGlobal = (d) => {
  let k = []

  if(d?.length)
     {
      sessionStorage.setItem('KITS', JSON.stringify(d))
      return;
     }
  try {
    k = JSON.parse(sessionStorage.getItem('KITS'))
    return k;
  }
  catch {
    return []
  }
}


export const customerFrustrationArray = [
// `Amazon orders that get approved and automatically cancelled by Amazon....
// Does anyone have any advice other than resubmitting and crossing fingers?
// This happens a lot to me....🥺`,
`Is Amazon canceling orders now? 😭😭 got a cancellation from Amazon but no details to why 😭?`,
`I just go the dreaded email from class wallet, followed by amazon cancellation due to price change 🥺🥺`,
`How do I resubmit an order since it took so long amazon cancelled because of change of price? 😢`


]

export function isValidURL(url) {
  try {
    new URL(url);
    return true; // If no error is thrown, the URL is valid
  } catch (e) {
    return false; // An error means the URL is invalid
  }
}
