import React from 'react';


const Modal = ({ title, onSubmit, onClose }) => {
    const [inputValue, setInputValue] = React.useState('');
  
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50" style={{zIndex: 99999999}}>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-lg font-bold mb-4">{title}</h2>
          
          <input
          autoFocus
            type="text"
            className="border p-2 rounded w-full"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            onKeyDown={e => {
              if(e?.code === 'Enter' || e?.code === 'NumpadEnter') {
                onSubmit(inputValue)
              }
            }}
          />
          <small>Required by ESA</small>
          <div className="mt-4 flex w-100">
            <button onClick={onClose} className="mr-2 px-4 py-2 bg-gray-300 rounded">
              Cancel
            </button>
            <button
              onClick={() => {
                if(!inputValue) {
                  return
                }
                onSubmit(inputValue)
              }}
              className="px-4 py-2 bg-blue-600 text-white rounded w-100"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default Modal;
  