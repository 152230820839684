import React from 'react'


export const PrivacyPolicy = () => {
return(
    <div class="p-20 w-100 fs-5">
    <h1 className='h1 mb-10'>Privacy Policy</h1>
    <p>
        We generate an approvable PDF file and a curriculum for each item you're intending to purchase with your ESA funds. You simply enter a few details, we generate the curriculum PDF for you, then you upload that PDF to ESA. That's it!
    </p>

    <h2 className='h2 mt-10 mb-4'>Your Privacy</h2>
    <p>
        To protect your privacy, we don't store any information you enter. We strictly use it to generate the curriculum and delete it instantly.
        That means we don't store or access any of the entered information including student name, application number, grade, subject, or materials. We don't have access to your payment method information or your email.
        <br/><br/>
        Once the PDF is generated, we no longer have access to any information you entered. We do not store or access the generated PDF either.
        <br/><br/>
        This is done to give you 100% privacy from us as parents to you.
    </p>
   
</div>
)

}