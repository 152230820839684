export const getKitInvoiceHtmlTemplate = ({
    // invoice
    invoiceNumber,
    issueDate,
    //customer
    billToName,
    billToEmail,
    billToAddress = '',
    isAdmin = false,
    // pricing
    kit = [{description: '', details: {shipping: 0, tax: 0, total: 0}}],
  }) => {
    const currency = '$'

    const {  shipping,
        tax,price,fees,
        total} = kit?.details;

    const companyName = 'New Lesson Plan'
    const companyAddress = '8410 E Thomas Rd'
    const companyCityStateZip = 'Scottsdale, AZ 85251'
    const companyCountry = 'USA'
    const companyEmail = 'support@newlessonplan.com'

    const itemsHtml =`
      <tr>
        <td style="padding: 10px; border-bottom: 1px solid #ddd;">${kit.title} Kit${kit.description?.length ? ' - ' + kit?.description : ''}</td>
        <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">1</td>
        <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">${currency}${kit.details.price}</td>
        <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">${currency}${kit.details.price}</td>
      </tr>
    `;
  
    return `<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Invoice</title>
  </head>
  <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f9f9f9; color: #333; line-height: 1.6;">
    <div style="max-width: 800px; margin: 20px auto; background: #fff; border: 1px solid #ddd; padding: 20px; border-radius: 8px;">
      <h1 style="font-size: 24px; margin-bottom: 20px; text-align: left;">Invoice</h1>
      <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
        <div>
          <p style="margin: 0;"><strong>Invoice number</strong>: ${invoiceNumber}</p>
          <p style="margin: 0;"><strong>Date of issue</strong>: ${issueDate}</p>
         
        </div>
        <div>
          <p style="margin: 0; text-align: right;"><strong>${companyName}</strong></p>
          <p style="margin: 0; text-align: right;">${companyAddress}</p>
          <p style="margin: 0; text-align: right;">${companyCityStateZip}</p>
          <p style="margin: 0; text-align: right;">${companyCountry}</p>
          <p style="margin: 0; text-align: right;">${companyEmail}</p>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
        <div>
          <p style="margin: 0;"><strong>Bill to</strong></p>
          <p style="margin: 0;">${billToName} - (${billToEmail})</p>
          <p style="margin: 0;">${billToAddress}</p>
        </div>
      </div>
      <div style="border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; padding: 20px; margin-bottom: 20px;">
       
        <p style="margin: 0;font-weight:bold;">New Lesson Plan's ${kit?.title} Kit</p>
        
        <br/>
      <p style="margin: 0;">
        Items included in the kit: 
        <ul>
          ${kit.details?.elements
            ?.map(e => e?.title + (isAdmin ? " _" + (e?.url || e?.URL) : ""))
            ?.map(f => `<li style="margin-bottom:5px;"> - ${f}</li>`)
            ?.join("")} <!-- Join the array into a single string -->
        </ul>
      </p>


      </div>
      <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
        <thead>
          <tr>
            <th style="text-align: left; padding: 10px; border-bottom: 1px solid #ddd;">Description</th>
            <th style="text-align: right; padding: 10px; border-bottom: 1px solid #ddd;">Qty</th>
            <th style="text-align: right; padding: 10px; border-bottom: 1px solid #ddd;">Unit price</th>
            <th style="text-align: right; padding: 10px; border-bottom: 1px solid #ddd;">Amount</th>
          </tr>
        </thead>
        <tbody>
          ${itemsHtml}
        </tbody>
      </table>
      <div style="text-align: right; margin-bottom: 20px;">
        <p style="margin: 0;">Subtotal: <strong>${currency}${price}</strong></p>
         <p style="margin: 0;">Taxes: <strong>${currency}${tax}</strong></p>
          <p style="margin: 0;">Shipping: <strong>${currency}${shipping}</strong></p>
        <p style="margin: 0;">Total: <strong>${currency}${total}</strong></p>        
      
      </div>
      <footer style="text-align: center; color: #aaa; font-size: 12px; margin-top: 20px;">
        <p style="margin: 0;">${invoiceNumber} · ${currency}${total}</p>
        <p style="margin: 0;">Page 1 of 1</p>
      </footer>
    </div>
  </body>
  </html>`;
  };
  