import React, { useState, useEffect } from "react";
import "./components/KitGrid.css";
import { FormButton, showMagniAlert } from "./components/formButton";
import { useParams } from "react-router-dom";
import LearningKitsModel from "./utils/LearningKitsModel";
import { ALLOWED_REFERRAL_CODES, GRADE, SAVE_ITEM_KEY } from "./constants";
import _ from "lodash";
import { FormInput } from "./components/formInput";
import { getKitInvoiceHtmlTemplate } from "./emailTemplates/kitInvoiceTemplate";
import PDFDocModel from "./pdfDocModel";
import alertify from "alertifyjs";
import { ScreenContainer } from "./App";
import { FeaturesList } from "./Home";
import { validateEmail } from "./NewCurriculum";
import { callEmailSvc } from "./utils/vGenerator";
import { getReferralCodeEmailTemplate } from "./emailTemplates/referralCode";

export const ReferralCodeLanding = () => {
  const [name, setName] = React.useState("NAME_NOT_PROVIDED");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  return (
    <ScreenContainer title="Get Your Own Referral Code" onBack={() => window.location.href = '/'}>
      <div className="p-6">
      

      {
      isSuccess && email?.length ? 
      <div className="mt-5 text-center align-items-center">
 <h3 className="text-xl font-medium  mb-6 text-success p-20">
          Congrats. We sent your referral code to your email ({email}). Whenever anyone uses your code at checkout and their purchase is approved, you'll get $50 from us.
          
        </h3>
        <FormButton label="Back to home page" isPrimary isFullWidth onClick={() => {
          window.location.href = '/' ;
        }}
        />
        </div>
        :
      <div className="mt-5">
          <h3 className="text-xl font-medium  mb-6 text-success">
          Make <strong className=" underline">$50</strong> for each approved learning kit purchase that used your code.
          
        </h3>
          <hr/>
         
          <div className="row mt-5">
          
          </div>
          <FormInput
            label="Enter your valid email below to get your own unique referral code:"
            text={email}
            onTextChange={(e) => setEmail(e)}
          />

          <hr className="my-6 border-gray-300" />

          <FormButton label={loading ? 'Loading...' :  "Generate My Referral Code"}
          isPrimary isFullWidth
          
          loading={loading}
          onClick={async () => {
            const isValid = validateEmail(email);
            if(!isValid) {
              alertify.alertify("Invalid Email", "Please enter a valid email to continue.")
              return;
            }

            setLoading(true)
            const code = await LearningKitsModel.generateReferralCode({email: email, name: name});
            setLoading(false)
            if(code?.length) {
              callEmailSvc('support@newLessonPlan.com', email, "Your Unique Referral Code - New Lesson Plan",
                 getReferralCodeEmailTemplate(code))
                setIsSuccess(true)

            }
          }}
          />
        </div>}
      </div>
    </ScreenContainer>
  );
};
