import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ScreenContainer } from "../App";
import { FormButton } from "../components/formButton";

export const CreateProduct = ({  }) => {
    const [loading, setLoading] = React.useState(false);
    const {kitId, kitName} = useParams();
  const [products, setProducts] = useState([]);
  const [productForm, setProductForm] = useState({
    URL: "",
    title: "",
    description: "",
    price: 20,
    tax: 4,
    imageUrl: "",
    isJunior: false,
    isBoth: false,
    kitId,
    isSpecialNeeds: false,
  });
  const [editingProductId, setEditingProductId] = useState(null);
  const isDev = false;
  const URL_PREFIX = isDev ?  "http://127.0.0.1:5001/newlessonplan-prod/us-central1" : 'https://us-central1-newlessonplan-prod.cloudfunctions.net';

  // Fetch products for a given kitId
  const fetchProducts = async () => {
    try {
        setLoading(true)
      const response = await fetch(`${URL_PREFIX}/handleProducts?kitId=${kitId}`);
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
    finally {
        setLoading(false)
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [kitId]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProductForm({
      ...productForm,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle image upload
  const handleImageUpload = async (e) => {
    setLoading(true)
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      const base64Data = reader.result.split(",")[1]; // Extract base64 data
      const mimeType = file.type;

      try {
        const response = await fetch(`${URL_PREFIX}/uploadImage`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imageBase64: base64Data,
            mimeType,
          }),
        });

        if (!response.ok) {
          throw new Error(`Image upload failed: ${response.statusText}`);
        }

        const { imageUrl } = await response.json();
        setProductForm({ ...productForm, imageUrl });
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error("Image upload error:", error);
      }
    };

    reader.readAsDataURL(file);
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      if (editingProductId) {
        await fetch(`${URL_PREFIX}/handleProducts?id=${editingProductId}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(productForm),
        });
      } else {
        await fetch(`${URL_PREFIX}/handleProducts`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(productForm),
        });
      }

      setProductForm({
        URL: "",
        title: "",
        description: "",
        price: 10,
        tax: 4,
        imageUrl: "",
        isJunior: false,
        isBoth: false,
        kitId,
        isSpecialNeeds: false,
      });
      setEditingProductId(null);
      fetchProducts();
      setShowAdd(false)
    } catch (error) {
      console.error("Submission error:", error);
    }
  };
  
  const cl = "rounded p-2 w-100 m-2 border-1 mb-10"

  // Handle deleting a product
  const handleDelete = async (productId) => {
    try {
      await fetch(`${URL_PREFIX}/handleProducts?id=${productId}`, {
        method: "DELETE",
      });
      fetchProducts();
    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  // Handle editing a product
  const handleEdit = (product) => {
    setShowAdd(true)
    setProductForm(product);
    setEditingProductId(product.id);
  };


  const [showAdd, setShowAdd] = React.useState(false)
  const renderAddProduct = () => {
    if(!showAdd) {
        return (<div className="m-10">
            <FormButton label="+ Add New Product"
            isPrimary isFullWidth
            onClick={() => setShowAdd(!showAdd)}
            />
        </div>)
    }

    return( <div className="p-10">
        <div>Add Product</div>

        <input
        className={cl}
          type="text"
          name="title"
          value={productForm.title}
          placeholder="Title"
          onChange={handleInputChange}
        />

<input
        className={cl}
          type="text"
          name="URL"
          value={productForm.URL}
          placeholder="Amazon URL"
          onChange={handleInputChange}
        />
        
        <textarea
        className={cl}
          name="description"
          value={productForm.description}
          placeholder="Description"
          onChange={handleInputChange}
        ></textarea>
        Price
        <input
        className={cl}
          type="number"
          name="price"
          value={productForm.price}
          placeholder="Price"
          onChange={handleInputChange}
        />
        Tax
         <input
        className={cl}
          type="number"
          name="tax"
          value={productForm.tax}
          placeholder="Tax"
          onChange={handleInputChange}
        />
          <img src={productForm.imageUrl} className="w-[200px] rounded m-2" />
        <input
        className={cl}
          type="file"
          onChange={handleImageUpload}
        />
        <div className={cl}>
        <input
       
          type="checkbox"
          name="isJunior"
          checked={productForm.isJunior}
          onChange={handleInputChange}
        />
        &nbsp; Junior Product
        </div>
        <div className={cl}>
        <input
       
          type="checkbox"
          name="isBoth"
          checked={productForm.isBoth}
          onChange={handleInputChange}
        />
        &nbsp; is Both?
        </div>
  
        <FormButton isPrimary isFullWidth label={editingProductId ? "Update Product" : "Add Product"}
  
        onClick={handleSubmit} />
          
          </div>)
  }

  return (
    <ScreenContainer title={"Products in -> " + kitName}>
     <div className="px-10 pb-20">
     {
            loading ? <div className="text-success border-2 text-center m-10 p-2 fs-3">Loading....</div> : ''
        }
     {renderAddProduct()}
      <ul>
        {products.map((product) => (
          <li key={product.id} className="w-100 border-5 p-2">
             <div className="d-flex underline cursor-pointer m-10 mb-10"
           // onClick={() => window.location.href ='/katy-products/' + kit.id + '/' + kit?.title}
            >
            <img src={product.imageUrl} alt={product.title}  className="w-[160px] h-[160px] bg-black rounded" />
            <div className="p-2 w-100">
            <h3 className="fs-2 fw-bold">{product.title}</h3>
            <p className="fw-bold">Price: ${product.price}</p>
            <p>Tax: ${product.tax}</p>
            <p>Description: {product.description}</p>
            <a href={product.URL} className="pr-40">URL: {product.URL?.substring(0, 40)}...</a>
            <p>isJunior: {JSON.stringify(product.isJunior) || 'false'}</p>
            <p>isBoth: {JSON.stringify(product.isBoth) || 'false'}</p>
            </div>
            </div>
            
            <div className="d-flex w-100 gap-3">
            <FormButton label="Edit" onClick={() => handleEdit(product)} isFullWidth/>
            <FormButton isDanger label="Delete" superExtraClass=" bg-danger" onClick={() => handleDelete(product.id)} isFullWidth />
            </div>
         
          </li>
        ))}
      </ul>
    </div>
    </ScreenContainer>
  );
};
