import React from "react";
import {
  callCouponSvc,
  CouponBanner,
  generateUniqueCoupon,
  getAvailableVouchers,
} from "./utils/vGenerator";
import { COUPON_ITEM_KEY, GRADE } from "./constants";
import { FormInput } from "./components/formInput";
import { FormButton } from "./components/formButton";
import LearningKitsModel from "./utils/LearningKitsModel";
import "./components/KitGrid.css";
import { FormSelect } from "./components/formSelect";
import { ScreenContainer } from "./App";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";

export const LearningKits = () => {
  const [coupon, setCoupon] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [checkedBalance, setCheckedBalance] = React.useState(null);
  const [showBalanceCheck, setShowBalanceCheck] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    sessionStorage.removeItem(COUPON_ITEM_KEY);

    if (window?.location?.href?.includes("check-balance")) {
      setShowBalanceCheck(true);
    }
  }, []);

  const [kits, setKits] = React.useState([])

  React.useEffect(() => {
    if(global.kits?.length) {
      setKits(global.kits)
      return;
    }
    LearningKitsModel.getLearningKitsFromDb().then(k => {
      setKits(k)
    })
  }, [])

  const renderCopy = () => {
    return (
      <section class="">
        <div class="max-w-4xl mx-auto text-center">
       
          <h2 class="text-3xl font-bold text-purple-700 mb-10 ">
            Avoid cancelled orders with NLP Learning Kits 
          </h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <div class="bg-purple-50 p-6 rounded-lg  ">
              <h3 class="text-lg font-semibold text-purple-800 mb-2">
                Fast Approval
              </h3>
              <p class="text-sm text-gray-600">
                We use our tried and proven curriculum generator to expedite approvals.
              </p>
            </div>
            <div class="bg-purple-50 p-6 rounded-lg ">
              <h3 class="text-lg font-semibold text-purple-800 mb-2">
                Tutor Approved
              </h3>
              <p class="text-sm text-gray-600">
                We work with ESA-compliant tutors to create each learning kit.
              </p>
            </div>
            <div class="bg-purple-50 p-6 rounded-lg  ">
              <h3 class="text-lg font-semibold text-purple-800 mb-2">
                Apple Products
              </h3>
              <p class="text-sm text-gray-600">
                We only use the best materials in our kits including Apple
                products in every kit.
              </p>
            </div>
            <div class="bg-purple-50 p-6 rounded-lg ">
              <h3 class="text-lg font-semibold text-purple-800 mb-2">
                No More Cancelled Orders
              </h3>
              <p class="text-sm text-gray-600">
                Your order will always be fulfilled, while Amazon and other
                vendors cancel orders if they runs out of stock or the price
                changes.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const [showModal, setShowModal] = React.useState(false);
  const [grade, setGrade] = React.useState('third')
  const [selectedKit, setSelectedKit] = React.useState(null)

  return (
   
      <div className=" m-10">
        {renderCopy()}

        <ScreenContainer title="Exclusive & Rare Finds Kits"
        onBack={() => navigate('/')}
        >
       
          <KitGrid kits={kits} 
          onClick={(kit) =>{
            setSelectedKit(kit)
          //  setShowModal(true)
       
            window.location.href = `kit-details/${kit?.id}/${grade}`
          }}
          />

<hr/>

<header className="bg-pink-500 text-white text-center py-6">
      
        <h2 className="text-3xl font-bold">
         Special Needs Learning Kits
        </h2>
        </header>
        <KitGrid kits={LearningKitsModel.getSpecialNeedsKits()} 
            onClick={(kit) =>{
              setSelectedKit(kit)
               window.location.href = `kit-details/${kit?.id}/${grade}`
            //  setShowModal(true)
            
            }}
            />
         

      {
        showModal ?  <div className=" fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50" style={{zIndex: 99999999}}>
        <div className="bg-white p-6 rounded-lg shadow-lg  ">
          <h2 className="text-lg font-bold mb-4">{`Select a grade to see your customized kit`}</h2>
          <FormSelect
          _options={GRADE.map(f => ({label: f, value:f}))}  onSelect={e => setGrade(e)}  placeholder="Select grade..."
          
            />
          <div className="d-flex gap-2">
        
<FormButton label="Cancel"  isFullWidth superExtraClass=" bg-gray-500 hover-bg-gray-500 hover:bg-gray-300" isPrimary
onClick={() => setShowModal(false)}
/>
<FormButton label="Go" isPrimary isFullWidth superExtraClass=" "

onClick={() => {
  if(!grade?.length) {
    return;
  }
    window.location.href = `kit-details/${selectedKit?.id}/${grade}`
}}
/>


            </div>
            <div className="m-2">
                </div>
            <small className="text-gray-400 pt-10">
            Kits are customized based on the student's grade level.
            </small>
          </div>

          

          </div>

          : null
     }
    </ScreenContainer>
    </div>
    
  );
};

export const Kit = ({ kit, index, onClick = () => {} }) => {
 
  return (
    <div
    onClick={() => onClick(kit)}
      key={index}
      className="w-full mb-10 sm:w-[calc(50%-1rem)] mt-4 lg:w-[calc(25%-1rem)] p-1 bg-white rounded-lg shadow-xl hover:shadow-lg transition-transform transform hover:-translate-y-1"
    >
      {kit?.title?.includes('Special Needs') ?  <div className="bg-pink-400 absolute top-1 rounded-[30px] shadow-md text-white fw-bold p-2"  >Special Needs ❤️</div> : null}
      <div className="w-full h-48  rounded-md overflow-hidden">
        <img
          src={kit.imgUrl}
          alt={kit.title?.replace('Special Needs', '')}
          className="w-full h-full object-cover"
          style={{ borderRadius: 15 }}
        />
      </div>
      <div className="text-center mt-3">
        <h5 className="text-xl font-semibold text-gray-800">{kit.title?.replace('Special Needs - ', '')}</h5>
        <p className="text-md text-gray-600 mt-1 text-left  p-2">
          {kit.description}
        </p>
      </div>

     
    </div>
  );
};

const KitGrid = ({ kits, onClick = () => {} }) => {
  return (
    <div>
    <div className="flex flex-wrap gap-4 justify-center cursor-pointer">
      {kits?.map((kit, index) => (
        <Kit kit={kit} index={index} onClick={onClick} />
      ))}
    </div>
    </div>
  );
};

export default KitGrid;
