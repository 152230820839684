import React from "react";
import "./mainPage.css"; // Create a corresponding CSS file for styles
import { useNavigate } from "react-router-dom";
import {
  COUPON_ITEM_KEY,
  customerFrustrationArray,
  SAVE_ITEM_KEY,
} from "./constants";
import { FormButton } from "./components/formButton";
export const Home = ({ onOk = () => {} }) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [imageSrc, setImageSrc] = React.useState(
    windowWidth >= 768
      ? require("./assets/images/desktop_header.png")
      : require("./assets/images/mobile.png")
  );

  React.useEffect(() => {
    const handleResize = () => {
      console.log("ksl" + window.innerWidth >= 768);
      setImageSrc(
        window.innerWidth >= 768
          ? require("./assets/images/desktop_header.png")
          : require("./assets/images/mobile.png")
      );
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    sessionStorage.removeItem(SAVE_ITEM_KEY);
    sessionStorage.removeItem(COUPON_ITEM_KEY);
  }, []);

  return (
    <div className="bg-white">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <MainBg />
        <div className="w-100 width-[100]" aria-hidden="true">
          <img
            src={require("./assets/images/classWallet_badge.png")}
            style={{ margin: "0 auto", height: 80 }}
            alt=""
          />
        </div>

        <div className="mx-auto max-w-2xl mt-10 mb-20">
          <div className="text-center">
            <h1
              className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl leading-loose"
              style={{ lineHeight: 1.2 }}
            >
              Your One-Stop Shop for
              <div className="text-pink-500">ESA Needs</div> 
            </h1>

            <div className="text-center center m-10 mb-0" aria-hidden="true">
              <img src={require("./assets/images/kids.png")} alt="" />
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-3 gap-2 max-w-5xl">
              <HomeTile
              key={'AMAZON_ORDER'}
                title="Guarantee Your Amazon Order"
                features={[
                  "We handle price & shipping changes",
                  "Faster approval vs. marketplace",
                ]}
                extraFeatures={['Paste your Amazon links', 'Get an invoice & curriculum', 'Will ship your order once approved', "We handle price & shipping changes",]}
                className="bg-yellow-200"
                iconName="amazon"
                btnColor="black"
                buttonTitle="Order"
                destination="/build-my-kit"
              />

              <HomeTile
              key={'CURRICULUM'}
                title="Get a Curriculum"
                features={[
                  "Don't risk rejection with generic AI curriculums",
                  "ESA-Compliant",
                ]}
                className="bg-indigo-100"
                iconName="book-fill"
                btnColor="indigo-500"
                buttonTitle="Generate"
                destination="/new"
                extraFeatures={['Fill in few ESA-required details', 'Pay $0.99', 'Get an ESA handbook-approved curriculum PDF', "Drop the PDF onto ClassWallet",]}
              />

              <HomeTile
              key={'LEARNING_KITS'}
                title="Buy a Learning Kit"
                features={[
                  "Teach your loved ones coding, science, and innovative topics.",
                  "ESA-Compliant",
                ]}
                className="bg-pink-200"
                iconName="lightbulb-fill"
                btnColor="pink-500"
                buttonTitle="Shop"
                destination="/learning-kits"
              />
            </div>

            {/* <div className=" border p-10 px-4 rounded text-center pt-0 border-top-0">
              <FeaturesList
                arr={[
                  "Paste your Amazon links",
                  `Get an invoice & curriculum to upload to ClassWallet`,
                  // "Ships to your doorstep once approved",
                  "We handle price & shipping changes",
                  "Faster approval vs. marketplace",
                ]}
              />

             
            </div> */}
          </div>

          <div className="mt-10 text-center pt-10">
            <h1
              className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl leading-loose"
              style={{ lineHeight: 1.2 }}
            >
              <div className="">See what parents are saying</div>
            </h1>

            {
              <div className="grid d-flex gap-2 mt-4 ">
                {customerFrustrationArray.map((complaintText) => (
                  <div className="transition shadow-xl hover:scale-125 p-2 py-3 border-1 rounded bg-white text-gray-500">
                    {complaintText}
                  </div>
                ))}
              </div>
            }
          </div>
          <div className="text-center center m-10 mb-0" aria-hidden="true">
            <img src={require("./assets/images/amazon_deny.png")} alt="" />
          </div>

          <div className="m-20">
            <div className="border-1 p-4 rounded border-pink-500 text-left mb-4">
              <h2 className="text-pink-500 text-xl fw-bold ">Did you know?</h2>
              <h2 className="text-xl text-left mt-2 ">
                Direct pay with us is 4 weeks faster to approve vs marketplace
              </h2>
            </div>
          </div>
          <FormButton
            isPrimary
            isFullWidth
            superExtraClass=" hover:scale-110 hover:shadow-xl bg-indigo-500 hover:bg-indigo-500 p-3 text-xl"
            label="Create Order"
            onClick={() => (window.location.href = "/build-my-kit")}
          />

    
        </div>

        <p className="mt-6 text-lg leading-8 text-gray-600 text-center"></p>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={windowWidth > 768 ? `text-center m-20` : ""}>
        <img src={imageSrc} alt="" className="header-image rounded" />

        <div style={{ margin: "0 auto", maxWidth: "80%" }}>
          <div
            className="btn-info w-100 mt-10 px-10 py-3 cursor-pointer text-center fs-4"
            onClick={() => (document.location.href = "/new")}
          >
            Create Your Curriculum
          </div>

          <div className="col-sm-12 text-center mt-10">
            <small></small>
          </div>
        </div>
      </div>
    </>
  );
};

export const MainBg = () => {
  return (
    <div
      className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
      aria-hidden="true"
      style={{ pointerEvents: "none" }}
    >
      <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
        }}
      />
    </div>
  );
};
export const MainHeader = () => {
  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt=""
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Product
          </a>
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Features
          </a>
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Marketplace
          </a>
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Company
          </a>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">→</span>
          </a>
        </div>
      </nav>
      {/* Mobile menu, show/hide based on menu open state. */}
      <div className="lg:hidden" role="dialog" aria-modal="true">
        {/* Background backdrop, show/hide based on slide-over state. */}
        <div className="fixed inset-0 z-50" />
        <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Product
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Features
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Marketplace
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Company
                </a>
              </div>
              <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export const FeaturesList = ({ arr = [] }) => {
  return (
    <div>
      <ul className="list-disc space-y-5 mt-1 text-left">
        {arr?.map((feat) => (
          <div class="d-flex align-items-center mb-2">
            <i class="bi bi-check text-success fs-4 "></i>
            <span>{feat}</span>
          </div>
        ))}
      </ul>
    </div>
  );
};



const HomeTile = ({
  features = [],
  title = "",
  iconName = "",
  bgColor = "",
  destination = "",
  buttonTitle = "",
  btnColor = "",
  extraFeatures = [],
  className = ''
}) => {
  return (
    <div
      class={`${className} rounded-lg p-6 shadow-md text-center hover:scale-125 hover:shadow-xl cursor-pointer transition flex flex-col justify-between h-full group`}
    >
      <div class="text-center mt-2">
        <div class={`text-${btnColor} text-4xl mb-4`}>
          <i class={`bi bi-${iconName}`}></i>
        </div>
        <h3 class="text-xl font-semibold text-gray-800 mb-4">{title}</h3>
        {/* Default Features */}
        <div class="group-hover:hidden">
          <FeaturesList arr={features} />
        </div>
        {/* Extra Features (Visible on Hover) */}
        <div class="hidden group-hover:block">
          <FeaturesList arr={extraFeatures?.length ? extraFeatures  : features} />
        </div>
      </div>
      <FormButton
        isPrimary
        isFullWidth
        superExtraClass={`mt-10 hover:scale-110 hover:shadow-xl bg-${btnColor} hover:bg-${btnColor} p-3 text-xl`}
        label={buttonTitle}
        onClick={() => (window.location.href = destination)}
      />
    </div>
  );
};


