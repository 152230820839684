import React, { useState, useEffect } from "react";
import "./components/KitGrid.css";
import { FormButton, showMagniAlert } from "./components/formButton";
import { useParams } from "react-router-dom";
import LearningKitsModel from "./utils/LearningKitsModel";
import {
  ALLOWED_REFERRAL_CODES,
  cleanedStringFromBlackListWords,
  getSetKitsGlobal,
  GRADE,
  isValidURL,
  SAVE_ITEM_KEY,
  SUBJECT,
} from "./constants";
import _ from "lodash";
import { FormInput } from "./components/formInput";
import { getKitInvoiceHtmlTemplate } from "./emailTemplates/kitInvoiceTemplate";
import PDFDocModel from "./pdfDocModel";
import alertify from "alertifyjs";
import { ScreenContainer } from "./App";
import { FeaturesList } from "./Home";
import { validateEmail } from "./NewCurriculum";
import { FormSelect } from "./components/formSelect";
import { LoadingSkeleton } from "./components/LoadingSkeleton";
import axios from "axios";
import { TotalTaxShippingRenderer } from "./LearningKitDetails";
import { KitCheckoutForm } from "./KitCheckout";
import { ProgressBar } from "react-bootstrap";


const MAX_ALLOWED = 5;
const TEST_AMAZON_PRODUCTS = ["https://www.amazon.com/SKKSTATIONERY-Assorted-Colorful-Assortment-Incentives/dp/B07BWK9P5P", "https://www.amazon.com/LEGO-Orchid-Building-Adults-Display/dp/B09Q4L157D", "https://www.amazon.com/Math-Riddles-Smart-Kids-Families/dp/1975644034", "https://www.amazon.com/2022-Apple-MacBook-Laptop-chip/dp/B0B3CDZLTB"];
const useLocal = false
const PREFIX = useLocal ? `http://127.0.0.1:5001/newlessonplan-prod/us-central1` :`https://us-central1-newlessonplan-prod.cloudfunctions.net` 
export const AmazonManager = () => {
const [stepProgress, setStepProgress] = React.useState(2)
const [isLinkBuilderMode, setIsLinkBuilderMode] = React.useState(false)
  const [subject, setSubject] = React.useState('')
  const [result, setResult] = React.useState(null);
  const [loading, setLoading] = React.useState("");
  const [priceDetails, setPriceDetails] = React.useState({total: 0, subtotal: 0, tax: 0, shipping: 0})
  const [isCheckoutMode, setIsCheckoutMode] = React.useState(false)
  const [kit, setKit] = React.useState({title: '', description: '', details: {price: 0, elements: []}})

  const [links, setLinks] = useState([""]); // Initialize with one empty link
  const [isTitlesReady, setIsTitlesReady] = React.useState(false);
  const [formattedProductTitles, setFormattedProductTitles] = React.useState([]);

    React.useEffect(() => {
      scrollToTop();
  
    }, [isCheckoutMode])
  
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Add smooth scrolling
      });
    };

  const readyUpTitlesForCurriculum = async () => {
    const url = `${PREFIX}/getAmznProductTitleV2`;
    
    const descArr = result?.map(p => `${cleanedStringFromBlackListWords(p?.title)} - ${cleanedStringFromBlackListWords(p?.description?.substring(0, 200))}`) || [];
  
    if (descArr?.length) {
      setLoading(true);
      setIsTitlesReady(false)
      try {
        // Map through each description and fetch individually
        const promises = descArr.map(async (desc) => {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ descArr: desc }), // Send each description individually
          });
  
          if (response.ok) {
            const r = await response.json();
            if (r?.reply) {
              try {
                const cleanedInput = cleanedStringFromBlackListWords(r?.reply?.trim() || '');
                return cleanedInput;
              } catch {
                return "";
              }
            }
          }
          return "";
        });
  
        // Wait for all promises to resolve
        const titles = await Promise.all(promises);

        // Process the returned titles
        if (titles.length) {
          const updatedKit = { ...kit };
          updatedKit.details.elements?.map((el, index) => {
            if (titles[index]?.length) {
              el.title = titles[index] + " " + el.dimensions// `${cleanedStringFromBlackListWords(el?.title)} - ${cleanedStringFromBlackListWords(titles[index]?.split('-')?.[1] || '')} ${el.dimensions || ''}`;
            }
          });
          const ts = updatedKit.details.elements?.map(f => f?.title)
          setFormattedProductTitles(ts);
        
          setIsTitlesReady(true)
        }
      } catch (error) {
        
      } finally {
        setLoading(false);
      }
    }
  };
  

  const cleanMoney = (num = '') => {
    if (typeof num !== 'string') return 0; // Ensure input is a string
    const price = parseFloat(num.replace(/[$,]/g, '')); // Remove `$` and `,` globally
    return Number.isNaN(price) ? 0 : price; // Use `Number.isNaN` for better precision
};

  const formatPrice = (r) => {
    const d = { ...priceDetails };

// Safely sum and calculate `subtotal`
d.subtotal = _.sum(
    r?.map(f => {
      return cleanMoney(f?.price)
     
    })
).toFixed(2); // Ensure two decimal places

// Safely calculate `tax`
d.tax = _.sum(
    r?.map(f => {
      return cleanMoney(f?.price)  * 0.09

    })
).toFixed(2);

// Safely calculate `shipping`
const amazonShipping = (
    _.sum(
        r?.filter(f => !f?.isPrime)?.map(f => {
            const shipping = cleanMoney(f?.shipping)
            return isNaN(shipping) ? 0 : shipping;
        })
    ) || 0
).toFixed(2) || 0;


const margin = parseFloat(d.subtotal) * 0.25;
const profitMargin =  parseFloat(
    margin > 50 ? 50 : margin < 16 ? 16 : margin
).toFixed(2);

d.shipping = (+amazonShipping || 0) + +profitMargin

// Calculate `total`
d.total = parseFloat(d.subtotal) + parseFloat(d.tax) + parseFloat(d.shipping)

// Update the state
const pDetails = {
  ...d,
  subtotal: parseFloat(d.subtotal).toFixed(2),
  tax: parseFloat(d.tax).toFixed(2),
  shipping: parseFloat(d.shipping).toFixed(2),
  total: parseFloat(d.total).toFixed(2),
}
setPriceDetails(pDetails);

// set kit
kit.details = {
  ...pDetails, 
  price: pDetails.total,
  elements: r
}

  }

  const scrapeLinks = async (validLinks) => {
    setLoading(true);

    const url = `https://us-central1-newlessonplan-prod.cloudfunctions.net/getAmazonProductsV3`// `http://127.0.0.1:5001/newlessonplan-prod/us-central1/getAmazonProductsV2`
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ urls: validLinks }), // Send JSON body with base64 file
    });

    if (response.ok) {
      const {products} = await response.json();
      
    setLoading(false);
    setResult(products);
    setIsEditProducts(false)
    const r = products;
    if(r?.[0]?.price?.length) {
      // set price details
      formatPrice(r)
  }
}
  };

  const handleAddInput = () => {
    setLinks([...links, ""]); // Add a new empty input field
  };

  const handleTextChange = (index, text) => {
    const updatedLinks = [...links];
    updatedLinks[index] = text; // Update the specific link in the array
    setLinks(updatedLinks);
  };

const [isEditProducts, setIsEditProducts] = React.useState(false)
  const renderLinksForm = () => {

    if(result?.length && !isEditProducts) {
      return null
    }

    return (<div>
       {links.map((link, index) => (
            <div className="align-items-center d-flex m-4">
              <div className=" rounded-[30px] text-indigo-500">{index + 1}</div>
              <div className="  w-2 h-2 bg-indigo-500 rounded-[30px] mx-2"></div>
            <div className="w-100">
            <FormInput
            autoFocus
            className="m-0"
              key={index}
              text={link}
              onTextChange={(text) => handleTextChange(index, text)} placeholder="Enter Amazon product link"
             // label={`Amazon Product Link ${links?.length > 1 ? index + 1 : ""}`}
            />
           </div>
            </div>
          ))}
         {links?.length >= MAX_ALLOWED ? null :  <div className="align-items-center text-center">
            {loading ? null :  <button onClick={handleAddInput} className="text-indigo-500">
              + Add Another Link
            </button>}
          </div>}
          <div className="m-10"></div>
          {
            loading? <div className="m-20">
              <AnimatedProgressBar  />

            </div> : null
          }
          <FormButton label={loading ? 'Building. Please wait...' :  "Build My Order"}
          
          disabled={loading}
          isPrimary isFullWidth
          onClick={() => {
            const validLinks = _.uniq(links?.filter(f=>f?.length && isValidURL(f)))
            const totalLinks = validLinks?.length
            if(totalLinks < 1) {
              alertify.alert("Empty", "Please enter at least one valid product link to build your order.")
              return;
            }
                // check links first
    const isValid = validLinks?.filter(f =>f?.includes('amazon.com'))?.length === validLinks?.length;
    if(!isValid) {
      alertify.alert("Invalid Link(s)", "One or more links you entered are not amazon products.");
      return;
    }
            setLinks(validLinks)
            scrapeLinks(validLinks)
           
          }}
          />
          <hr />
    </div>)

  }

  const getTitles = () => {
    const t = result?.map(product => cleanedStringFromBlackListWords(product?.title || ''))?.join(', ')
  }

  const getMaterials = () => {
    let m = formattedProductTitles?.map(title =>{
      
      // let t = title.replace(/,/g, '')?.split('-')?.[0]
      // if(t?.length > 3) {
      //   return t
      // }
      return title//?.replace(/,/g, " ");
    })
    
    m = m?.join(', ');
   // m = cleanedStringFromBlackListWords(m)
    //console.log("SENDING TO CUR: " + m)
    return m;

  }



  const renderLinkBuilder = () => {
    return (<div className="transition">
         <div className="p-6">
      <div className="col-lg-6">
       
        </div>
        {
          renderFormTitle( result?.length ? 
            `Review the products and total, then hit 'Continue'`
            :
            `Paste your Amazon product links below. You can add up to ${MAX_ALLOWED} links.
         `)
        }
       
        {
          result?.length > 0 && isLinkBuilderMode && !isEditProducts ?
          <div className="align-items-center align-self-center text-center cursor-pointer mb-2"
          onClick={() => {
            setIsLinkBuilderMode(true)
            setIsEditProducts(true)
          }}
          >
          <a className=" underline text-indigo-500">Edit products</a> 
          </div>
          : null
        }

        <div className="border-1 p-10 rounded">
         {isEditProducts ?  renderLinksForm() : 
         <div>
          {
            result?.map(product => <div className="text-center m-10 mb-10">
              <AmazonProductPreview product={product}  />
            </div>)
          }


          {
            result?.length ? <div>
            <TotalTaxShippingRenderer
            {...priceDetails}
            />

            </div> : null
          }

          {
            result?.length ? <FormButton label="Continue"
            isPrimary isFullWidth loading={loading}
            onClick={() => {
              setFormattedProductTitles([])
              readyUpTitlesForCurriculum();
              setIsCheckoutMode(true)
            }}
            />

            : null
          }
          :
         </div>
  }

        </div>
      </div>
    </div>)
  }

  const renderFormTitle = (t) => {
    return  <div className="fw-bold fs-4 mb-3 text-center">{t}</div>
  }

  const renderSubjectForm = () => {

    return (<div className="p-20">
         <div className="mb-10">
          {renderFormTitle`Select a subject to group your order under (required by ESA)`}
          
        <FormSelect className=" mt-3" _options={SUBJECT.map(f => ({label: f, value:f}))} onSelect={e => setSubject(e)} 
        footNote={"* One subject per order"}
        />
        <div className="m-10" />
        <FormButton label="Continue"
            isPrimary isFullWidth loading={loading}
            onClick={() => {
            if(!subject?.length) {
              alertify.alert("Missing Subject", "Subject is required by ESA to group your Amazon purchases under.")
              return;
            }
            setIsLinkBuilderMode(true)
            setIsEditProducts(true)
            }}
            />
        </div>
    </div>)
  }


  const renderCheckout = () => {
    return (<KitCheckoutForm kit={kit} materials={getMaterials()} total={priceDetails.total} _subject={subject} checkoutEnabled={isTitlesReady}
    onBack={() => setIsCheckoutMode(false)} noHeader
    />)
  }


  return (
    <div>
      {<ClassWalletBadge />}
    <ScreenContainer
      title="Build Your Amazon Order"
      hideBackButton={!isCheckoutMode && !isLinkBuilderMode}
      onBack={() => {
       if(isCheckoutMode) {
        setIsCheckoutMode(true)
        setIsCheckoutMode(false)
        
        return;
       }
       if(isLinkBuilderMode) {
        setIsLinkBuilderMode(false)
        setLinks([""])
        
        setSubject(null)
        return;
       }
      }}
    >
      <ProgressBar now={`${

        isLinkBuilderMode && !result?.length ? 40 : 
        isLinkBuilderMode  && result?.length && !isCheckoutMode ? 60 : 
        isCheckoutMode ? 90 : 
        !subject?.length ? 2 : 
        subject?.length && !isLinkBuilderMode ? 2 : 
        100

      }`} className="rounded-0 h-2" />
   {
   !isLinkBuilderMode ? renderSubjectForm() : 
   isCheckoutMode ? 
   renderCheckout()
      :
   renderLinkBuilder()}
    </ScreenContainer>
    </div>
  );
};


const AmazonProductPreview = ({product}) => {
  return(<div key={product?.id} className="w-100 border-2 rounded p-2 shadow-xl hover:scale-105 transition">
    <div className="d-flex cursor-pointer m-10 mb-10"
  
   >
   <img src={product?.image}  className="w-[160px] h-[160px] bg-black rounded mr-5" />
   <div className="p-2 w-100 text-left">
   <h3 className="fs-4 fw-bold text-left">{product.title}</h3>
   <p className="fw-bold fs-3 text-success mt-2">Price: {product.price}</p>
   {/* <p className="fw-bold fs-4 text-success mt-2">Shipping: {product.shipping}</p> */}
  {
    product?.isPrime && 1==2 ? <img src={require('./assets/images/prime.png')} 
    className="m-2 rounded w-[60px]"
    /> : null
  }
   <p className="mt-2">{product?.description?.substring(0, 60)}...</p>
  
   </div>
   </div>
  

 </div>)
}


const AnimatedProgressBar = ({ animationLength = 10 }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = animationLength * 30; // Break animation length into 100 steps
        const step = 100 / (animationLength * 10); // Calculate step increment

        const timer = setInterval(() => {
            setProgress((prev) => {
                const nextProgress = prev + step;
                return nextProgress >= 100 ? 100 : nextProgress;
            });
        }, interval);

        if (progress >= 100) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [animationLength, progress]);

    return (
        <div className="progress" style={{ height: '30px', borderRadius: '5px', overflow: 'hidden' }}>
            <div
                className="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                role="progressbar"
                style={{ width: `${progress}%`, transition: `width ${animationLength}s linear` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
            >
                {Math.round(progress)}%
            </div>
        </div>
    );
};

export default AnimatedProgressBar;




const scrapeUrlsLocally = async (urls = []) => {

  const result = []

  await Promise.all(urls?.map(async url => {
   const r =  await scrapeAmazon(url)
   result.push(r)
  }))
return result;
}

const scrapeAmazon = async (url) => {
  try {
     
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/'; // Free CORS proxy
      const response = await axios.get(`${proxyUrl}${url}`, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });

      // Scrape data using DOMParser
      const parser = new DOMParser();
      const doc = parser.parseFromString(response.data, 'text/html');

      const title = doc.querySelector('#productTitle')?.innerText.trim();
      const price = doc.querySelector('.a-price .a-offscreen')?.innerText.trim();
      const shipping = doc.querySelector('#mir-layout-DELIVERY_BLOCK-slot-PRIMARY_DELIVERY_MESSAGE span')?.innerText.trim();
      const image = doc.querySelector('#landingImage')?.src || doc.querySelector('#imgTagWrapperId img')?.src;
      const description = doc.querySelector('#feature-bullets')?.innerText.trim() 
          || doc.querySelector('#productDescription')?.innerText.trim();

      return { title, price, shipping, image, description }
  } catch (err) {
     return null
  }
};




export const ClassWalletBadge = () => {
  return( <div className="w-100 width-[100]" aria-hidden="true">
    <img
      src={require('./assets/images/classWallet_badge.png')}
      style={{ margin: '0 auto', height: 80 }}
      alt=""
    />
  </div>)
}