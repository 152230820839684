import React from 'react'
import { ScreenContainer } from './App'


export const HowDirectPayWorks = () => {


    const steps = [
        require('./assets/images/kit-help/1.png'),
        require('./assets/images/kit-help/2.png'),
        require('./assets/images/kit-help/3.png'),
        require('./assets/images/kit-help/4.png'),
        require('./assets/images/kit-help/5.png'),

    ]

    return(<>
    <ScreenContainer title="Next steps after you get your invoice and curriculum"
    onBack={() => window.location.href = '/'}
    >
    <div className='grid p-10'>

<p className='h1 fs-bold text-left px-10 '></p>
<p className='p-10 fs-5'>
    Below are the steps you would take after getting the invoice and curriculum for your custom Amazon order
</p>

    {
        steps?.map((img, index) => <div>

<p className='h1 fs-bold text-left px-10 pt-10 mt-5 mb-10'> Step{index + 1}</p>

<img src={img} style={{ margin:'0 auto', }} />

        </div>)
    }
</div>
    </ScreenContainer>
    </>)
}