import React, { useState, useEffect, useRef } from 'react';
import './autocomplete.css';
import _ from 'lodash';
import Modal from './modal';
import { SPECIAL_TAGS } from '../constants';

const FormAutocompleteDropdown = ({
  onGetSelectedValues = () => {},
  fillInThisTagName = null,
  _selectedTags = [],
  allowNewValues = true,
  fillInTagsOnInit = false,
  placeholder = 'Click to add materials'
}) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalPlaceholder, setModalPlaceholder] = useState(''); 
  const suggestionsList = _selectedTags;
  const suggestionsRef = useRef(null);

  useEffect(() => {
    onGetSelectedValues(_.uniq(selectedValues));
  }, [selectedValues]);

  useEffect(() => {
    if (_selectedTags?.length && fillInTagsOnInit) {
      setSelectedTags(_selectedTags?.map(tag => tag?.name));
      setShowSuggestions(false);
      onGetSelectedValues(_selectedTags?.map(f => f?.name));
    }
    if (_selectedTags?.length && fillInThisTagName?.length) {
      const selected = [fillInThisTagName];
      setSelectedTags(selected);
      setShowSuggestions(false);
      onGetSelectedValues(selected);
    }
  }, [_selectedTags]);

  // Update suggestions based on input value
  useEffect(() => {
    if (inputValue) {
      const filteredSuggestions = suggestionsList.filter(item =>
        item?.name?.toLowerCase().includes(inputValue?.toLowerCase())
      );
      setSuggestions([]);
      setTimeout(() => {
        setSuggestions(filteredSuggestions);
      }, 0);
      setShowSuggestions(true); // Ensure suggestions are shown when typing
    } else {
      setShowSuggestions(false); // Hide suggestions when input is empty
    }
  }, [inputValue]);

  const handleInputChange = (e) => {
    if (!allowNewValues) {
      setInputValue('');
      return;
    }
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if ((e.key === 'Enter' || e.key === 'Tab') && inputValue) {
      if (!inputValue?.trim()?.length) {
        return;
      }
      handleTagSelection(inputValue);
      setInputValue('');
      setShowSuggestions(false);
    }
  };

  const handleTagSelection = (tagText = '') => {
    const specialTags = SPECIAL_TAGS;
    if (!selectedTags.includes(tagText)) {
      // Check if tagText matches any of the values in specialTags
      if (specialTags.some((specialTag) => {
        const lowerCaseTagText = tagText.toLowerCase().trim();
        const lowerCaseSpecialTag = specialTag.toLowerCase().trim();
        const lengthDifference = Math.abs(lowerCaseTagText.length - lowerCaseSpecialTag.length);
        return lowerCaseTagText.includes(lowerCaseSpecialTag) && lengthDifference <= 3;
      })) {
        setModalPlaceholder(tagText); // Set the placeholder tag
        setShowModal(true); // Show the modal for specific tags
      } else {
        addTag(tagText); // Directly add the tag if not a special case
      }
    }
  };

  const addTag = (tagText = '') => {
    setSelectedTags([...selectedTags, tagText]);
    setSelectedValues([...selectedValues, tagText]);
    onGetSelectedValues([...selectedValues, tagText]);
  };

  const removeTag = (tagText = '') => {
    const updatedTags = selectedTags.filter(tag => tag !== tagText);
    const updatedValues = selectedValues.filter(tag => tag !== tagText);
    setSelectedTags(updatedTags);
    setSelectedValues(updatedValues);
    onGetSelectedValues(updatedValues);
  };

  const handleSuggestionClick = (suggestion = '') => {
    handleTagSelection(suggestion?.name || suggestion);
    setInputValue(''); // Clear input after selection
    setShowSuggestions(false);
  };

  const handleModalSubmit = (title) => {
    removeTag(modalPlaceholder); 
    addTag(`${modalPlaceholder} - ${title}`); 
    setShowModal(false);
    setModalPlaceholder('');
  };

  const detectClick = (event) => {
    const clickedId = getClickedElementId(event);
    const isOutside = !clickedId?.includes('SUGGESTION_') && !clickedId?.includes('dropDownInputFormAutoComplete');
    if (inputValue.trim() !== '' && isOutside && !modalPlaceholder) {
      handleTagSelection(inputValue);
      setInputValue('');
      setShowSuggestions(false);
    }
    if (isOutside) {
      setShowSuggestions(false);
    } 
  };

  useEffect(() => {
    document.addEventListener('click', detectClick);
    return () => document.removeEventListener('click', detectClick);
  }, [inputValue]);

  const handleFocus = () => {
    if (inputValue.length === 0) {
      // Show all suggestions if input is empty
      setSuggestions(suggestionsList);
    }
    setShowSuggestions(true);
  };
  

  return (
    <div className=" ">
      <div className="input-container border-gray-400 col-lg-12">
        {selectedTags.map(tag => (
          <div className="tag" key={tag}>
            <span>{tag}</span>
            <span className="remove-tag bi bi-x list-none fs-5" onClick={() => removeTag(tag)}></span>
          </div>
        ))}
        <input
          id="dropDownInputFormAutoComplete"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onClick={handleFocus}
          placeholder={placeholder}
        />
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <ul className="suggestions-list" ref={suggestionsRef} id={'suggestionsList' + JSON.stringify(suggestions)}>
          {suggestions.map((suggestion, index) => (
            <li id={`SUGGESTION_` + index} key={suggestion?.name} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion?.name}
            </li>
          ))}
        </ul>
      )}

      {showModal && (
        <Modal
          title={`What's the name of the ${modalPlaceholder}?`}
          onSubmit={handleModalSubmit}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default FormAutocompleteDropdown;

function getClickedElementId(event) {
  return event.target.id || null;
}
