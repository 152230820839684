import React from 'react'


export const HowReimbursementWorks = () => {


    const steps = [
        require('./assets/images/reimburse-1.png'),
        require('./assets/images/reimburse-2.png'),
        require('./assets/images/reimburse-3.png'),
        require('./assets/images/reimburse-4.png'),
        require('./assets/images/reimburse-5.png'),

    ]

    return(<>
    <div className='grid p-10'>

    <p className='h1 fs-bold text-left px-10 '> How to Submit for Reimbursement </p>
    <p className='p-10 fs-5'>
        Below are the steps you want to take to get reimbursed every time you purchase a curriculum or a voucher from us.
    </p>

        {
            steps?.map((img, index) => <div>

<p className='h1 fs-bold text-left px-10 pt-10 mt-5 mb-10'> Step{index + 1}</p>
 
 <img src={img} style={{ margin:'0 auto', }} />

            </div>)
        }
    </div>
    </>)
}