import React, { useState, useEffect } from "react";
import "./components/KitGrid.css";
import { FormButton, showMagniAlert } from "./components/formButton";
import { useParams } from "react-router-dom";
import LearningKitsModel from "./utils/LearningKitsModel";
import { ALLOWED_REFERRAL_CODES, getSetKitsGlobal, GRADE, SAVE_ITEM_KEY } from "./constants";
import _ from 'lodash'
import { FormInput } from "./components/formInput";
import { getKitInvoiceHtmlTemplate } from "./emailTemplates/kitInvoiceTemplate";
import PDFDocModel from "./pdfDocModel";
import alertify from "alertifyjs";
import { ScreenContainer } from "./App";
import { FeaturesList } from "./Home";
import { validateEmail } from "./NewCurriculum";
import { FormSelect } from "./components/formSelect";
import { LoadingSkeleton } from "./components/LoadingSkeleton";


export const LearningKitDetails = () => {

const [isKitLoading, setIsKitLoading] = React.useState(true);
const [loading, setLoading] = React.useState('')
  const [studentName, setStudentName] = React.useState('')
  const [applicationNumber, setApplicationNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [streetAddress, setStreetAddress] = React.useState('')
  const [apt, setApt] = React.useState('')
  const [city, setCity] = React.useState('')
  const [state, setState] = React.useState('AZ')
  const [zip, setZip] = React.useState('')
  const [grade, setGrade] = React.useState('')

  const [isCheckoutMode, setIsCheckoutMode] = React.useState(false);
  const [kit, setKit] = useState(null);
  const [isIpad, setIsIpad] = React.useState(false);
  const [isAirPods, setIsEarPods] = React.useState(false);
  const [isMacbook, setIsMacbook] = React.useState(false);
  const [isJunior, setIsJunior] = useState(false);
  const [isMsLaptop, setIsMsLaptop] = useState(false);
  const [referralCode, setReferralCode] = React.useState('')

  const [showShippingInfo, setShowShippingInfo] = React.useState(false);


  const [addons, setAddons] = useState({
    replaceMacbookWithIpad: false,
    addAppleEarpods: false,
  });

  const {id} = useParams();


  const initialize = async () => {
    if(id) {
      setIsKitLoading(true)
      if(!getSetKitsGlobal()?.length) {
      const f = await LearningKitsModel.getLearningKitsFromDb()
          getSetKitsGlobal(f)
      }
      const isJ = true// GRADE.findIndex(f =>f === grade) < 7
      let selectedKit = LearningKitsModel.getLearningKits(false, false, false, isJ)?.find(k => k?.id === id)

      || LearningKitsModel.getSpecialNeedsKits(false, false, false, isJ)?.find(k => k?.id === id)

      || getSetKitsGlobal()?.find(k => k?.id === id)

      if(selectedKit?.id &&  !selectedKit?.details?.[0]?.id && !selectedKit?.id?.includes('INTERNAL')) {
        const details = await LearningKitsModel.getDbKitDetails(false, false, false,  selectedKit?.id)
        selectedKit.details = details;
      }

      if (selectedKit?.id) {
        setKit(selectedKit);
        setIsJunior(isJ);
      }
      setIsKitLoading(false)
    }
  }

  useEffect(() => {
        initialize();
  }, [id]);


  React.useEffect(() => {
    scrollToTop();

  }, [isCheckoutMode])


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling
    });
  };



  React.useEffect(() => {

    if(kit?.id) {
      let k = {...kit}
      let updatedKit = LearningKitsModel.updateKitPriceWithAddOns(kit?.details?.elements, isIpad, isMacbook, isAirPods,isMsLaptop, k)
      setKit(updatedKit)
    }

  }, [isIpad, isMacbook, isAirPods, isMsLaptop])




if(isKitLoading) {
  return (<div>
     <ScreenContainer title="loading Kit...">
    <LoadingSkeleton />
    </ScreenContainer>
  </div>)
}


if (!kit) {
  return (
    <div className="text-center py-20">
      <h2 className="text-2xl font-semibold text-gray-600">
        No kit selected. Please select a kit to view details.
      </h2>
    </div>
  );
}


  const renderCheckoutForm = () => {

    return( 
      
      <ScreenContainer title="Checkout" onBack={() => setIsCheckoutMode(false)}>
     
        <div className="p-6">
          <h3 className="text-lg font-medium text-indigo-500 mb-6">
          Please provide these ESA-required details to generate your invoice and curriculum for easy drag-and-drop on ClassWallet.
          </h3>
    
          <div className="mt-5">
            {/* Student and ESA Information */}
            <div className="row">
            
            <div className="col">
            <FormInput label="Student Name" text={studentName} onTextChange={e => setStudentName(e)} autoFocus
            />
            </div>
            <div className="col">
            <FormInput label="ESA Application Number"  text={applicationNumber} onTextChange={e => setApplicationNumber(e)} />
            </div>
            </div>
            <div className="row">
            
            <div className="col">
            <FormInput label="ESA Email"  text={email} onTextChange={e => setEmail(e)} />
            </div>
            <div className="col">
            <FormSelect className=" mt-3" _options={GRADE.map(f => ({label: f, value:f}))} label="Grade" onSelect={e => setGrade(e)}  placeholder="Select grade..."  />
            </div>
            </div>
    
            <hr className="my-6 border-gray-300" />
    
          {showShippingInfo? <div>
 
            <div className="row">
            
            <div className="col">
            <FormInput label="Street Address"  text={streetAddress} onTextChange={e => setStreetAddress(e)} />
            </div>
            <div className="col">
            <FormInput label="Apartment/Suite (Optional)"  text={apt} onTextChange={e => setApt(e)} />
            </div>
            </div>


            <div className="row">
            
            <div className="col">
            <FormInput label="City"  text={city} onTextChange={e => setCity(e)} />
            </div>
            <div className="col">
            <FormInput label="State"  text={state} onTextChange={e => setState(e)} />
            </div>
            <div className="col">
            <FormInput label="Zip Code" maxLength={5}  text={zip} onTextChange={e => setZip(e)} />
            </div>
            </div>
          <hr/>

            <div className="col mt-4">
            <FormInput label="Do you have a referral code?" maxLength={5}   text={referralCode} onTextChange={e => setReferralCode(e)} />
            </div>
          </div> : null}

      <hr/>
 {showShippingInfo ?  <h4 className="text-left text-lg   mt-4 px-4">
               <span className="font-bold text-purple-600">
      Total: ${kit?.details?.total}
    </span>
              </h4> : null}

      <div className="bg-gray-50 p-4 rounded-lg d-flex gap-2">
             
           
               {/* <FormButton label="Pay With Card & Submit For Reimbursement" isPrimary isFullWidth superExtraClass=" bg-black" /> */}
               <FormButton label={loading ? 'Loading...' :  !showShippingInfo ? 'Next: Shipping Info' :  "Generate Invoice for ClassWallet"} isPrimary isFullWidth
                loading={loading}
                onClick={async () => {
                  if(!showShippingInfo) {
                    if(!validateEmail(email)) {
                      alertify.alert("Invalid Email", "ESA requires your email to review/approve purchases. Please provide a valid email to continue.")
                      return;
                    }
                    if(!grade?.length) {
                      alertify.alert("Grade Required", "Grade level is required to generate the supplemental curriculum.")
                      return;
                    }
                    setShowShippingInfo(true);
                    return;
                  }
                  const materials = kit?.details?.elements?.map?.(e => e?.title)?.join(', ')
                  
                  if(!city?.length || !state?.length || !zip?.length || !streetAddress?.length || !studentName?.length || !applicationNumber?.length || !email?.length || !grade?.length) {
                    alertify.alert("Missing Info", "Please fill out all fields as they're required by ESA for approval.")
                    return;
                  }
                  setLoading(true)
                  const enteredCode = referralCode || ''
                  const isValidCode = await LearningKitsModel.checkRCode(enteredCode);
                  const code = isValidCode ? enteredCode : 'NLP' 
                  const invoiceNumber = LearningKitsModel.generateUniqueInvoiceNumber(code)
                  const html = getKitInvoiceHtmlTemplate({invoiceNumber: invoiceNumber, issueDate: formatDate(), billToEmail: email, billToName: studentName,
                    kit: kit, billToAddress: `${streetAddress} ${apt?.length ? '#'+apt : ''}, ${city} ${state} ${zip}`,

                  })

                  // admin html
                  const adminHtml = getKitInvoiceHtmlTemplate({invoiceNumber: invoiceNumber, issueDate: formatDate(), billToEmail: email, billToName: studentName, isAdmin: true,
                    kit: kit, billToAddress: `${streetAddress} ${apt?.length ? '#'+apt : ''}, ${city} ${state} ${zip}`,

                  })

                  // generate curriculum and invoice
                  const data = {studentName: studentName, applicationNumber: applicationNumber, subject: kit?.subject || kit?.title,
                    grade: grade, materials:materials, adminHtml: adminHtml, 
                    html: html, isKit: true,invoiceNumber: invoiceNumber,
                     getAsJson: false, email: email};
                     if(kit?.isSpecialNeeds) {
                      data.disability = 'Special Needs'
                     }
                     setLoading(false)
                  //save data 
                  sessionStorage.setItem(SAVE_ITEM_KEY, JSON.stringify(data))
                   window.location.href = '/success'
                }}
               />
             </div>
          </div>
        </div>
     </ScreenContainer>
          )
  }


  const renderTotalAndTax = () => {

    return (<div>
  <hr className="my-6 border-gray-300" />
  
  {/* Updated Pricing Section */}
  <div className="bg-gray-50 p-4 rounded-lg">
    <h4 className="text-right text-gray-700 mb-2">
      Kit Price:{" "}
      <span className="font-bold text-gray-800">${kit?.details?.price || ''}</span>
    </h4>
    <h4 className="text-right text-gray-700 mb-2">
      Tax: <span className="font-bold text-gray-800">${kit?.details?.tax || ''}</span>
    </h4>
    <h4 className="text-right text-gray-700 mb-2">
      Shipping:{" "}
      <span className="font-bold text-gray-800">
        ${kit?.details?.shipping || ''}
      </span>
    </h4>
    <hr className="my-4 border-gray-400" />
    <h4 className="text-right text-xl font-bold text-purple-600">
      Total: ${kit?.details?.total}
    </h4>
  </div>

    </div>)
  }

  const renderBoxContents = () => {
    return (
      <div className="relative w-full mx-auto overflow-hidden shadow-xl transition hover:scale-105">
        <div
          className="absolute inset-0 bg-cover bg-center shadow-xl"
          style={{
            backgroundImage: `url(${require('./assets/product-images/box.jpg')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            borderRadius: 30,
          }}
        ></div>
        <div className="flex flex-wrap justify-center space-x-4 space-y-4 relative z-10 mt-3" style={{ borderRadius: 30 }}>
          {kit.details.elements.map((el, index) => {
            return (
              <div
              onMouseEnter={() => document.getElementById(`TXT_${index}`).classList.add('scale-125')}
              onMouseLeave={() => document.getElementById(`TXT_${index}`).classList.remove('scale-125')}
              id={'IMG_'+index}
                key={index}
                className="flex justify-center items-center rounded-full bg-cover bg-center shadow-md transition hover:scale-125"
                style={{
                  width: 160, // Increased size
                  height: 160, // Increased size
                  backgroundImage: `url(${el.imageUrl})`,
                  marginBottom: '15px', // Add margin between images
                }}
              >
               
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  


  const renderKitDetails = () => {
    return (

      <ScreenContainer title={`What's Included?`}
      key={`${isAirPods}-${isIpad}-${isMacbook}`}

      onBack={() => window.location.href = '/learning-kits'}
      >
  
          <div className="p-6">
            <h3 className=" fw-bold font-medium text-gray-700  px-5"
            style={{fontSize: 60}}
            >
              {kit?.title} Kit
              
            </h3>
            {/* <small className="mx-5 fs-4 text-indigo-500">{_.capitalize(_.lowerCase(grade))} grade</small> */}
            <div
              id="photoCollage"
              className="row"
            >
              <div className="col-6 overflow-visible mt-5"
              
style={{  borderRadius: 30}}>

      {renderBoxContents()}


</div>

<div className="col-6 mt-4">

      <div className="relative">
        <div className=" p-4 ">
          
             <ul className="list-disc pl-5 space-y-1">
                    {
                      kit?.details?.elements?.map(e => e?.title)?.map((feat, index) => (  <div class=" d-flex align-items-center hover:scale-125 transition-all ease-in-out mb-4"
                        id={'TXT_'+index}
                        onMouseEnter={() => document.getElementById(`IMG_${index}`).classList.add('scale-125')}
                        onMouseLeave={() => document.getElementById(`IMG_${index}`).classList.remove('scale-125')}
                      >
                        <i class="bi bi-dot text-indigo-500 fs-4 me-2"></i>
                        <span>{feat}</span>
                       
                      </div>
                      ))
                    }
                    </ul>
         
        </div>
      </div>

</div>


            </div>
  
            <hr className="my-6 border-gray-300" />
  
            {/* Add Ons & Savings Section */}
            
             <div className="bg-gray-50 p-4 rounded-lg mb-6">
              <h3 className="text-lg font-bold text-gray-700">
                Add Ons & Savings
              </h3>

              <small className="text-success">We discount Apple products when added to learning kits which makes them cheaper than anywhere else on the marketplace</small>
             <div className=" mb-4"></div>
              <div className="flex items-center justify-between mb-3">
                <label className="text-gray-600">
                  Add iPad 10th Gen
                </label>
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-600"
                  checked={isIpad}
                  onChange={async () => {
                    setIsIpad(!isIpad)
                  }}
                />
              </div>

              <div className="flex items-center justify-between mb-3">
                <label className="text-gray-600">
                  Add MacBook Air
                </label>
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-600"
                  checked={isMacbook}
                  onChange={() => {
                    setIsMacbook(!isMacbook)
                  }}
                />
              </div>
  
              <div className="flex items-center justify-between">
                <label className="text-gray-600">
                  Add Apple EarPods
                </label>
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-600"
                  checked={isAirPods}
                  onChange={() => {
                    setIsEarPods(!isAirPods)
                  }}
                />

                
              </div>

              <div className="flex items-center justify-between mt-3">
                <label className="text-gray-600">
                  Add Microsoft Surface Laptop
                </label>
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-600"
                  checked={isMsLaptop}
                  onChange={() => {
                    setIsMsLaptop(!isMsLaptop)
                  }}
                />

                
              </div>
              
            </div>
          {renderTotalAndTax()}
  
              <hr/>
            
              <div>
  
              </div>
  
            <div className="bg-gray-50 p-4 rounded-lg d-flex gap-2">
             
            <FormButton label="Checkout" isPrimary isFullWidth
            onClick={() => setIsCheckoutMode(true)}
            />
  
            </div>
          </div>
          </ScreenContainer>
    );
  }

  if(isCheckoutMode) {
    return renderCheckoutForm()
  }

  return renderKitDetails()


};

const formatDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();

  return `${month}-${day}-${year}`;
};


export const TotalTaxShippingRenderer = ({subtotal = 0, tax = 0, total = '', shipping = 0}) => {

  return (<div>
<hr className="my-6 border-gray-300" />

{/* Updated Pricing Section */}
<div className="bg-gray-50 p-4 rounded-lg">
  <h4 className="text-right text-gray-700 mb-2">
    Subtotal:{" "}
    <span className="font-bold text-gray-800">${(subtotal || '')}</span>
  </h4>
  <h4 className="text-right text-gray-700 mb-2">
    Tax: <span className="font-bold text-gray-800">${(tax || '')}</span>
  </h4>
  <h4 className="text-right text-gray-700 mb-2">
    Shipping:{" "}
    <span className="font-bold text-gray-800">
      ${(shipping || '')}
    </span>
  </h4>
  <hr className="my-4 border-gray-400" />
  <h4 className="text-right text-xl font-bold text-purple-600">
    Total: ${(total || '')}
  </h4>
</div>

  </div>)
}