import React, { FC } from "react";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';


const FormSelect  = ({label = '', defaultValue, _options = [], footNote, onSelect = () => {}, 
className = 'mb-10',
allowCreatingNewElements = false, isNewEntryEmail = false, isUserInvite = false, placeholder = 'Select....'}) => {
   

    const [options, setOptions] = React.useState(_options);
    const [newValues, setNewValues] = React.useState([])
    const handleCreate = (inputValue = '') => {
      if(global.isDemoMode) {
        return;
      }
        if (inputValue?.length) {
          const newOption = { value: inputValue, label: `Create ${inputValue}?` };
          _options = _options.push(newOption)
          onSelect(inputValue)
          setNewValues(newValues?.concat(inputValue))
        }
      };

  

    return (
        <>
            <div className={className}>
                {label?.length ?  <label className='form-label fw-bold'>{label}</label> : null}
                {
                    allowCreatingNewElements ? 
                    
                        <CreatableSelect
                        blurInputOnSelect
                        placeholder={placeholder}
                          isClearable
                          onChange={e => {
                            onSelect(e?.value)
                        }}
                          onCreateOption={handleCreate}
                          options={_options}
                        />
                      
                      :
                
                <Select 
                value={defaultValue}
                placeholder={placeholder}
                    className='react-select-styled react-select-solid' 
                    classNamePrefix='react-select' 
                    options={_options} 
                    onChange={e => {
                       
                        onSelect(e?.value)
                    }}
                />
                    }
                   {
        footNote?.length ? <small className="form-text text-muted px-2">{footNote}</small> : null 
      }
            </div>
        </>
    )
}

export { FormSelect };
