import React from 'react';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



export const LoadingSkeleton = () => {
    return(<div className='p-10 m-20'>
        <Skeleton height={100} />
        <Skeleton height={300} />
        <Skeleton height={50} />
       
    </div>)
}
