import React from 'react'
import { SAVE_ITEM_KEY } from './constants'
import DataModel from './DataModel'
import { ProgressLoadingView } from './components/progressview';
import PDFDocModel from './pdfDocModel';
import { adminEmailMeOrderDetails } from './utils/vGenerator';
import { HowDirectPayWorks } from './HowDirectPayWorks';

export const Success = () => {

    const [valid, setValid] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const [o, setO] = React.useState(null);
    const [isKit, setIsKit] = React.useState(false)
    const process = async () => {

        const s = sessionStorage.getItem(SAVE_ITEM_KEY);
        try {
            if(global.isProcessing) {
                return;
            }
            global.isProcessing = true;
            let obj = JSON.parse(s);
            if(obj.grade) {
                setValid(true)
                setIsLoading(true)
                setO(obj)
                setIsKit(obj?.isKit && obj?.html)
                await DataModel.createCurriculum(obj, obj?.isKit)
                if(obj?.isKit && obj?.html) {
                    setIsKit(true)
                    // send me an email
                    PDFDocModel.generateInvoicePDF(obj.html, `${obj?.invoiceNumber}`)
                    // email me details
                    adminEmailMeOrderDetails(obj.adminHtml, `${obj?.invoiceNumber} - ADMIN ONLY`)
                    sessionStorage.removeItem(SAVE_ITEM_KEY)
                    }
                    setIsLoading(false)
            }
        }
        catch (e) {
        
        window.location.href = '/'
        }
        finally {
            global.isProcessing = false;
        }
    }
    const prefix =  `  Download is complete.`
    const getDownloadDoneMessage = () => {
        if(isKit) {
            const m = `Grab the invoice and curriculum from your download folder and drop it onto your ClassWallet for approval. Once approved, we will ship your order right to your doorstep.`
            return m;
        }
        let msg = `Grab the PDF from your downloads folder and drop it onto your ESA portal as shown below.`
        if(o?.getAsJson) {
            msg = `Grab the document from your downloads folder, fill in student name and application number, and drop it onto your ESA portal as shown below.`
        }
        return msg; 
    }

    React.useEffect(() => {
        process()

    }, [])

    if(!valid) {
        return <></>
    }
    return(<div className=' text-center  m-10 p-10 mt-10 rounded mb-10 mt-5 mb-60 bg-[#0dcaf0] ' style={{maxWidth: '80%', alignSelf:'center', margin: '0 auto', marginBottom: 200, background:isLoading || 1===1?  'rgba(1, 1, 1, .03)': 'rgb(120 235 23 / 12%)'}}>
        <p className={`fs-1 ${isLoading ? ' fw-bold' : ''}`}>{
            isLoading ? (isKit ? 'Generating your invoice & curriculum. Please wait...' :  ' Creating Your Curriculum. Please wait...' ):
            <div>
            <div className='row ' >
            <div className='bi bi-check-all  fw-bold' style={{color: global.green}}>{prefix}</div>
            </div>
            <div className={`h2 m-10 `}>
            {getDownloadDoneMessage()}
            </div>
            </div>
            
            }</p>

            {
                isLoading ? <div className='text-center w-100 p-20 h-[300px]'>
                <ProgressLoadingView/>
                </div>
                :
                isKit ? <div>
                      <a>
                        <p
                            className="p-10 fs-5 text-indigo-500 underline cursor-pointer"
                            onClick={() => (window.location.href = '/how-direct-pay-works')}
                        >
                            Click here to see a step-by-step guide to submit the generated invoice and curriculum to ClassWallet.
                        </p>
                    </a>
                    <HowDirectPayWorks />
                </div>
                :
                <div className='mt-20'>
          
              <img src={require('./assets/images/how_to_upload_curriculum.png')} style={{width: '80%', margin:'0 auto', borderColor: 'black', borderWidth: 1, borderRadius: 12}} />

              <div className='mt-5'>
                <p className='py-5'>Didn't get the document or see an error?</p> <div className='btn btn-info'
                onClick={() => process()}
                >
                    Click here to re-download
                </div>
                </div>
                </div>
                    }
    </div>)
}